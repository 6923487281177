import { styled } from 'styled-components';
import ReactSimplyCarousel from 'react-simply-carousel';
import { useState } from 'react';
import CarExterior from '../../../assets/illustrations/CarExterior.svg?react';
import CarDashboard from '../../../assets/illustrations/CarDashboard.svg?react';
import CarInterior from '../../../assets/illustrations/CarInterior.svg?react';
import { breakpointDesktop } from '@moller/design-system';
import i18next from 'i18next';

const CarouselWrapper = styled.div`
    display: flex;
    position: relative;
    margin-bottom: var(--moller-spacing-s);

    .dot-nav {
        width: 20px;
        height: 3px;
        background-color: var(--moller-palette-neutral-40);
        margin: 0 var(--moller-spacing-base);
        border: none;
        outline: none;
    }
    .dot-nav-active {
        background-color: var(--moller-palette-black);
    }
`;

const Slide = styled.div`
    display: flex;
    justify-content: center;
    flex: 1;
    padding: var(--moller-spacing-xxs) 0 var(--moller-spacing-s);
    width: calc(100vw - 48px);

    @media screen and (min-width: ${breakpointDesktop}) {
        width: auto;
        padding: 0 var(--moller-spacing-base) 0 0;
        &:last-of-type {
            padding-right: 0;
        }
    }

    svg {
        width: auto;
        height: 160px;

        @media screen and (min-width: ${breakpointDesktop}) {
            height: 140px;
            padding: 0;
        }
    }
`;

export const ImageGuidelines = () => {
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const isDesktop = window.screen.width > 1024;

    return (
        <>
            <CarouselWrapper>
                <ReactSimplyCarousel
                    activeSlideIndex={activeSlideIndex}
                    onRequestChange={setActiveSlideIndex}
                    backwardBtnProps={{ show: false }}
                    forwardBtnProps={{ show: false }}
                    itemsToScroll={1}
                    easing="ease"
                    speed={250}
                    preventScrollOnSwipe
                    touchSwipeTreshold={30}
                    mouseSwipeTreshold={30}
                    itemsToShow={isDesktop ? 3 : 1}
                    hideNavIfAllVisible
                    disableNavIfAllVisible
                    infinite={!isDesktop}
                    dotsNav={{
                        show: !isDesktop,
                        itemBtnProps: { className: 'dot-nav' },
                        activeItemBtnProps: {
                            className: 'dot-nav dot-nav-active',
                        },
                    }}
                >
                    <Slide>
                        <CarExterior />
                    </Slide>
                    <Slide>
                        <CarDashboard />
                    </Slide>
                    <Slide>
                        <CarInterior />
                    </Slide>
                </ReactSimplyCarousel>
            </CarouselWrapper>
            <p>{i18next.t('imageGuidelines')}</p>
        </>
    );
};

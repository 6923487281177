import moment from 'moment/moment';
import Compressor from 'compressorjs';

const resizeImage = async (file: File): Promise<Blob> => {
    return await new Promise((resolve, reject) => {
        new Compressor(file, {
            quality: 0.8,
            maxHeight: 1200,
            maxWidth: 1200,
            success: (result) => resolve(result),
            error: reject,
            convertTypes: ['image/png', 'image/heic'],
            convertSize: 1000000,
        });
    });
};

const handleFile = async (file: File) => {
    const resizedFile = await resizeImage(file).then((res) => res);

    return new Promise((resolve) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(resizedFile);
        fileReader.onload = () => {
            resolve({
                name: file.name,
                lastModifiedDate: file.lastModified
                    ? moment(file.lastModified).format()
                    : '',
                data: fileReader.result,
            });
        };
    });
};

export const handleMultipleFiles = async (files: FileList | null) => {
    if (files) {
        return await Promise.all(
            Array.from(files).map(async (file) => {
                return await handleFile(file);
            })
        );
    }
    return undefined;
};

import i18next from 'i18next';
import { Banner } from '@/views/my-page-contract-signed/MyPageContractSigned';
import { DensitySizes } from '@moller/design-system/foundation/typography';
import parse from 'html-react-parser';
import { usePurchaserEmail } from '@/utils/usePurchaserEmail';

export const GeneralErrorAlert = ({
    className,
    density = 'default',
}: {
    className?: string;
    density?: DensitySizes;
}) => {
    const { email } = usePurchaserEmail();

    return (
        <Banner
            className={className}
            type="error"
            density={density}
            message={
                parse(
                    i18next.t('generalErrorMessage', {
                        email: email,
                    })
                ) as string
            }
        />
    );
};

import {
    formInputs,
    FormProvider,
    useLocalFormState,
} from '@moller/design-system';
import {
    required,
    validCellPhoneNumber,
} from '@moller/design-system/utilities/validation';
import i18next from 'i18next';
import { styled } from 'styled-components';
import { FormButtons } from './FormButtons';
import { InputWrapper } from './CarStep';
import { useRequestOneTimeCode } from '../../queries/useRequestOneTimeCode';
import { LoadingOverlay } from '@/components/loading/LoadingOverlay';
import { FormErrorAlert } from './FormErrorAlert';
import { useGetUrlParams } from '../../utils/useGetUrlParams';

type PhoneNumberStepFormModel = {
    phoneNumber: string;
};

const { TextField } = formInputs<PhoneNumberStepFormModel>();

export const Info = styled.p`
    margin-bottom: var(--moller-spacing-s);
`;

interface PhoneNumberStepProps {
    goToNextStep: () => void;
    goToPreviousStep: () => void;
    setPhoneNumber: (phoneNumber: string) => void;
    phoneNumber: string;
    authToken: string;
}

export const PhoneNumberStep = ({
    goToNextStep,
    goToPreviousStep,
    setPhoneNumber,
    phoneNumber,
    authToken,
}: PhoneNumberStepProps) => {
    const { phoneNumberFromUrl } = useGetUrlParams();
    const form = useLocalFormState<PhoneNumberStepFormModel>(
        { phoneNumber: phoneNumberFromUrl || phoneNumber },
        {
            phoneNumber: required(validCellPhoneNumber),
        }
    );
    const requestOneTimeCode = useRequestOneTimeCode(
        form.state.validated.phoneNumber,
        () => {
            setPhoneNumber(form.state.validated.phoneNumber || '');
            goToNextStep();
        }
    );
    const { isLoading, isError, error } = requestOneTimeCode;

    const setErrorMessage = () => {
        const errorResponseData = error?.isAxiosError && error.response?.data;

        if (errorResponseData && errorResponseData.hasReachedFrequencyLimit) {
            return 'hasReachedFrequencyLimitError';
        }
        return 'requestOneTimeCodeGeneralError';
    };

    return (
        <FormProvider
            id="phoneNumberStepForm"
            form={form}
            onSubmit={() => {
                authToken ? goToNextStep() : requestOneTimeCode.mutate();
            }}
            hideNecessityText
        >
            {isLoading && <LoadingOverlay delayMillis={0} fixed />}
            <Info>{i18next.t('phoneNumberInfo')}</Info>
            <InputWrapper>
                <TextField
                    field="phoneNumber"
                    label={i18next.t('phoneNumber')}
                    prefix="+47"
                    helperText={i18next.t('phoneHelpText')}
                    type="tel"
                    inputMode="tel"
                    autoComplete="tel"
                    trailingIcon={authToken ? 'check' : undefined}
                    disabled={!!authToken}
                />
            </InputWrapper>
            <FormButtons
                extraMargin
                primaryAction={{
                    textKey: authToken ? 'next' : 'sendOneTimeCode',
                }}
                secondaryAction={{
                    textKey: 'previous',
                    onClick: () => goToPreviousStep(),
                }}
            />
            {isError && <FormErrorAlert textKey={setErrorMessage()} />}
        </FormProvider>
    );
};

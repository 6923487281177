import { styled } from 'styled-components';
import { Icon, Subtitle } from '@moller/design-system';

export const Content = styled.div`
    display: flex;
    flex-direction: column;

    > div {
        display: flex;
        align-items: center;

        h3 {
            margin: 0 var(--moller-spacing-xxs) 0 0;
        }
    }

    p {
        margin-top: var(--moller-spacing-base);
    }

    svg path {
        fill: var(--moller-color-primary);
    }
`;

interface LinkCardContentProps {
    title: string;
    description: string;
}

export const LinkCardContent = ({
    title,
    description,
}: LinkCardContentProps) => {
    return (
        <Content>
            <div>
                <Subtitle>{title}</Subtitle>
                <Icon icon="arrow_forward" />
            </div>
            <p>{description}</p>
        </Content>
    );
};

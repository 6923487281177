import React, { ReactNode, useEffect, useState } from 'react';

interface DelayedProps {
    readonly delayMillis?: number;
    readonly children: ReactNode;
}

export const Delayed: React.FC<DelayedProps> = ({
    children,
    delayMillis = 800,
}) => {
    const [showChildren, setShowChildren] = useState(!delayMillis);

    useEffect(() => {
        const timeout = setTimeout(() => setShowChildren(true), delayMillis);
        return () => clearTimeout(timeout);
    }, [delayMillis]);

    return showChildren ? <>{children}</> : null;
};

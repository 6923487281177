import { LinkCard } from '@moller/design-system';
import { useEffect } from 'react';
import i18next from 'i18next';
import { PageTitle } from './MyPage';
import { Page } from '@/components/Page';
import { styled } from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { PATH_MY_PAGE } from '@/utils/url/routes';
import parse from 'html-react-parser';
import { ErrorMessage } from './components/ErrorContent';
import { useGetMyPage } from './queries/useGetMyPage';
import { LoadingOverlay } from '@/components/loading/LoadingOverlay';
import { PageHeader } from '@/components/PageHeader';
import { LinkCardContent } from '@/components/LinkCardContent';

export const ContentWrapper = styled.div`
    margin-top: var(--moller-spacing-s);

    > a {
        margin-bottom: var(--moller-spacing-s);
        cursor: pointer;
        text-decoration: none;
        color: var(--moller-color-on-background);

        svg path {
            fill: var(--moller-color-primary);
        }
    }
`;

export const MyPageOverviewView = () => {
    const navigate = useNavigate();
    const { data: valuations, isError, isLoading, isSuccess } = useGetMyPage();
    const noValuations = valuations?.length === 0;

    useEffect(() => {
        const hasOnlyOneRequest = valuations?.length === 1;

        if (hasOnlyOneRequest && isSuccess) {
            navigate(`${PATH_MY_PAGE}/${valuations[0].id}`);
        }
    }, [isSuccess, navigate, valuations]);

    if (isLoading) {
        return <LoadingOverlay delayMillis={0} />;
    }

    return (
        <>
            <PageHeader titleTextKey="myPageOverviewTitle" />
            <Page>
                {isError ? (
                    <>
                        <PageTitle>{i18next.t('errorTitle')}</PageTitle>
                        <ErrorMessage>{i18next.t('myPageError')}</ErrorMessage>
                    </>
                ) : (
                    <>
                        {noValuations && (
                            <PageTitle>
                                {i18next.t('noValuationsTitle')}
                            </PageTitle>
                        )}
                        <p>
                            {parse(
                                i18next.t(
                                    noValuations
                                        ? 'noValuationsDescription'
                                        : 'myPageOverviewDescription'
                                )
                            )}
                        </p>
                    </>
                )}
                <ContentWrapper>
                    {valuations?.map((valuation) => (
                        <LinkCard
                            key={valuation.id}
                            $as={Link}
                            to={`${PATH_MY_PAGE}/${valuation.id}`}
                        >
                            <LinkCardContent
                                title={
                                    valuation.car.brand
                                        ? `${valuation.car.brand} ${valuation.car.model}`
                                        : i18next.t('unknown')
                                }
                                description={valuation.car.registrationNumber}
                            />
                        </LinkCard>
                    ))}
                </ContentWrapper>
            </Page>
        </>
    );
};

import i18next from 'i18next';
import { styled } from 'styled-components';
import { useParams } from 'react-router';
import { useGetMyPageValuation } from '../../my-page/queries/useGetMyPageValuation';
import { SelfDeclaration } from '../../self-declaration/components/SelfDeclaration';
import { Divider } from '@/components/Divider';

export const DealerCommentHeading = styled.h3`
    font-size: var(--moller-typeface-size-base);
    font-weight: 500;
    margin: 0 0 var(--moller-spacing-base);
`;

export const DealerComment = styled.p`
    font-weight: 300;
    padding-bottom: var(--moller-spacing-s);
`;

export const CarStep = () => {
    const { id } = useParams<{ id: string }>();
    const { data: valuation } = useGetMyPageValuation(id);

    if (valuation?.selfDeclaration) {
        return (
            <div>
                <SelfDeclaration selfDeclaration={valuation.selfDeclaration} />
                {valuation.selfDeclaration.dealerComment && (
                    <>
                        <Divider />
                        <DealerCommentHeading>
                            {i18next.t('dealerComment')}
                        </DealerCommentHeading>
                        <DealerComment>
                            {valuation.selfDeclaration.dealerComment}
                        </DealerComment>
                    </>
                )}
            </div>
        );
    } else return <></>;
};

import { RadioButton } from '@moller/design-system';
import i18next from 'i18next';
import {
    ErrorMessage,
    ErrorIcon,
    RadioGroup,
    RadioGroupWrapper,
    FormRadioButtonProps,
} from '../../my-page-contract/components/FormRadioGroup';
import { styled } from 'styled-components';

export const CablesRadioGroup = styled(RadioGroup)`
    display: flex;
    flex-wrap: wrap;
    row-gap: var(--moller-spacing-xxs);
    > div {
        padding-right: var(--moller-spacing-xs);

        &:last-of-type {
            padding: 0;
        }
    }
`;

export const ChargingCablesRadioGroup = ({
    form,
    field,
    className,
    disabled = false,
}: FormRadioButtonProps) => {
    const inputProps = form.inputProps(field);
    const hasValue = inputProps.value !== undefined;
    const errorMessage = inputProps.validity?.message;

    return (
        <RadioGroupWrapper className={className}>
            <p>{i18next.t(field)}</p>
            <CablesRadioGroup id={field}>
                <RadioButton
                    label={i18next.t('oneCable')}
                    onChange={() => inputProps.setValue(i18next.t('oneCable'))}
                    id={`${field}-oneCable`}
                    name={field}
                    value={inputProps.value === i18next.t('oneCable')}
                    validity={{
                        type: errorMessage && !hasValue ? 'error' : 'none',
                    }}
                    disabled={disabled}
                />
                <RadioButton
                    label={i18next.t('twoOrMoreCables')}
                    onChange={() =>
                        inputProps.setValue(i18next.t('twoOrMoreCables'))
                    }
                    id={`${field}-twoOrMoreCables`}
                    name={field}
                    value={inputProps.value === i18next.t('twoOrMoreCables')}
                    validity={{
                        type: errorMessage && !hasValue ? 'error' : 'none',
                    }}
                    disabled={disabled}
                />
                <RadioButton
                    label={i18next.t('noCables')}
                    onChange={() => inputProps.setValue(i18next.t('noCables'))}
                    id={`${field}-noCables`}
                    name={field}
                    value={inputProps.value === i18next.t('noCables')}
                    validity={{
                        type: errorMessage && !hasValue ? 'error' : 'none',
                    }}
                    disabled={disabled}
                />
            </CablesRadioGroup>
            {errorMessage && !hasValue && (
                <ErrorMessage>
                    <ErrorIcon icon="error" />
                    {errorMessage}
                </ErrorMessage>
            )}
        </RadioGroupWrapper>
    );
};

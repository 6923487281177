export enum PriceEstimateCustomerResponse {
    INTERESTED = 'Interested',
    PRICE_TOO_LOW = 'PriceTooLow',
    VALUE_EXPLORATION = 'ValueExploration',
    SELLING_TO_SOMEONE_ELSE = 'SellingToSomeoneElse',
    OTHER = 'Other',
}

export interface PriceEstimateModel {
    marketMinPrice: number;
    marketMaxPrice: number;
    offerMinPrice: number;
    offerMaxPrice: number;
    customerResponse?: PriceEstimateCustomerResponse;
    externalEstimateDeviation: boolean;
}

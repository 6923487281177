import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MY_VALUATION_PRICE_ESTIMATES } from '@/http/endpoints';
import { put } from '@/http/put';
import format from 'string-format';
import { PriceEstimateCustomerResponse } from '@/types/PriceEstimateModel';

type PriceEstimatePayload = {
    customerResponse: PriceEstimateCustomerResponse;
    customerResponseComment?: string;
};

export const useUpdatePriceEstimate = (
    id: string | undefined,
    onSuccess?: () => void
) => {
    const updatePriceEstimate = (payload: PriceEstimatePayload) =>
        put<PriceEstimatePayload, unknown>(
            format(MY_VALUATION_PRICE_ESTIMATES, id || ''),
            payload
        ).request;
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (payload: PriceEstimatePayload) =>
            updatePriceEstimate(payload),
        onSuccess: () => {
            void queryClient.invalidateQueries({
                queryKey: ['myPageValuation', id],
            });
            onSuccess && onSuccess();
        },
    });
};

import { styled } from 'styled-components';
import { SelfDeclarationModel } from '@/types/SelfDeclarationModel';
import i18next from 'i18next';

const List = styled.dl`
    margin: 0;
`;

const Item = styled.div`
    margin-bottom: var(--moller-spacing-s);

    dt {
        font-weight: 500;
    }

    dd {
        margin: 0;
        font-weight: 300;
    }
`;

interface SelfDeclarationProps {
    readonly selfDeclaration: SelfDeclarationModel;
}

export const SelfDeclaration = ({ selfDeclaration }: SelfDeclarationProps) => {
    type SelfDeclarationItem = {
        field: keyof SelfDeclarationModel;
        value: boolean | string;
    };
    const selfDeclarationArray = Object.entries(selfDeclaration).map(
        ([field, value]): SelfDeclarationItem =>
            ({ field, value } as SelfDeclarationItem)
    );
    const filteredSelfDeclarationArray = selfDeclarationArray.filter(
        (item) => item.value !== '' && item.field !== 'dealerComment'
    );

    const fieldValue = (value: boolean | string) =>
        value === true
            ? i18next.t('yes')
            : value === false
            ? i18next.t('no')
            : value;

    return (
        <List>
            {filteredSelfDeclarationArray.map((item, index) => (
                <Item key={index}>
                    <dt>{i18next.t(item.field)}</dt>
                    <dd>{fieldValue(item.value)}</dd>
                </Item>
            ))}
        </List>
    );
};

import i18next from 'i18next';
import { DensitySizes } from '@moller/design-system/foundation/typography';
import { styled } from 'styled-components';
import { AlertBanner } from '@moller/design-system';
import parse from 'html-react-parser';
import { SHARED_PURCHASER_EMAIL } from '@/utils/emailUtils';

export const Banner = styled(AlertBanner)`
    margin: var(--moller-spacing-m) 0 var(--moller-spacing-xl);

    a {
        color: var(--moller-color-on-background);
        font-weight: 500;
    }
`;

export const FormErrorAlert = ({
    textKey = 'generalErrorMessage',
    className,
    density = 'compact',
}: {
    textKey?: string;
    className?: string;
    density?: DensitySizes;
}) => {
    return (
        <Banner
            className={className}
            type="error"
            density={density}
            message={
                parse(
                    i18next.t(textKey, {
                        email: SHARED_PURCHASER_EMAIL,
                    })
                ) as string
            }
            dismiss={() => undefined}
        />
    );
};

import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as translationsNO from './translations-no';

const resources = {
    no: translationsNO,
};

void i18next.use(initReactI18next).init({
    compatibilityJSON: 'v3',
    resources,
    lng: 'no',

    interpolation: {
        escapeValue: false,
    },
});

if (import.meta.hot) {
    import.meta.hot.accept('./translations-no.ts', (newTranslations) => {
        i18next.addResourceBundle(
            'no',
            'translation',
            newTranslations?.translation,
            false,
            true
        );
        void i18next.changeLanguage('no');
    });
}

import { ONE_TIME_CODE } from '@/http/endpoints';
import { post } from '@/http/post';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

type RequestOneTimeCodeError = {
    hasReachedFrequencyLimit?: boolean;
};

export const useRequestOneTimeCode = (
    phoneNumber: string | undefined,
    onSuccess?: () => void
) => {
    const requestOneTimeCode = () =>
        post(ONE_TIME_CODE, {
            phoneNumber,
        }).request.then((res) => res.data);

    return useMutation({
        mutationFn: () => requestOneTimeCode(),
        onSuccess: () => onSuccess && onSuccess(),
        onError: (error: AxiosError<RequestOneTimeCodeError>) => error,
    });
};

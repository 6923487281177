import i18next from 'i18next';
import { styled } from 'styled-components';
import {
    breakpointDesktop,
    breakpointTablet,
} from '@moller/design-system/utilities/layout';
import {
    characterCount,
    formInputs,
    FormProvider,
    PrimaryButton,
    useLocalFormState,
} from '@moller/design-system';
import {
    CompanyCarFormModel,
    companyCarFormValidator,
    initialCompanyCarFormValues,
} from '../utils/companyCarFormUtils';
import { useEffect, useState } from 'react';
import { sortedDealers } from '../utils/sortedDealers';
import { useGetUser } from '@/auth/AuthContext';
import { DealerModel } from '@/types/DealerModel';
import { useGetCar } from '../utils/useGetCar';
import { useGetCity } from '../utils/useGetCity';
import { useFeatureFlags } from '@/utils/feature-toggle/useFeatureFlags';
import { SvvDownAlertMessage } from '@/components/SvvDownAlertMessage';

interface ValuationRequestViewProps {
    readonly dealers: DealerModel[] | undefined;
    readonly handleOnSubmit: (
        data: CompanyCarFormModel,
        selectedDealer?: DealerModel
    ) => void;
}

const { TextField, TextArea, SingleSelect } = formInputs<CompanyCarFormModel>();

export const InputLayout = styled.div`
    display: flex;
    flex-wrap: wrap;
    column-gap: var(--moller-spacing-xl);
    row-gap: var(--moller-spacing-xs);
    margin-bottom: var(--moller-spacing-xs);

    .hidden-mobile {
        display: none;

        @media screen and (min-width: ${breakpointDesktop}) {
            display: flex;
        }
    }

    > span {
        flex: 1 1 auto;
    }

    @media screen and (min-width: ${breakpointTablet}) {
        > span {
            flex: 1 1 40%;
        }
    }
`;

const CompactInputLayout = styled(InputLayout)`
    row-gap: 0;
    margin-bottom: 0;

    @media screen and (min-width: ${breakpointTablet}) {
        margin-bottom: calc(var(--moller-spacing-base) * -1);
    }
`;

export const SubmitLayout = styled.div`
    margin: var(--moller-spacing-l) 0 var(--moller-spacing-s);
    button {
        min-width: 100%;
    }

    @media screen and (min-width: ${breakpointTablet}) {
        button {
            min-width: 240px;
        }
    }
`;

export const SectionTitle = styled.h2`
    font-size: var(--moller-typeface-size-base);
    font-weight: 500;
    margin: 0;
`;

const DealerSectionTitle = styled(SectionTitle)`
    display: flex;
    margin-top: var(--moller-spacing-xl);
`;

const CompanyCarInfo = styled.p`
    margin-bottom: var(--moller-spacing-xxs);
    margin-top: var(--moller-spacing-base);
`;

const StyledUl = styled.ul`
    margin-top: var(--moller-spacing-xxs);
    margin-bottom: var(--moller-spacing-base);
    padding-left: var(--moller-spacing-l);
`;

export const CompanyCarForm = ({
    dealers,
    handleOnSubmit,
}: ValuationRequestViewProps) => {
    const { selectDealerOptional } = useFeatureFlags();
    const form = useLocalFormState<CompanyCarFormModel>(
        initialCompanyCarFormValues,
        companyCarFormValidator(!!selectDealerOptional),
        { showNecessityOn: 'required' }
    );
    const t = (key: string) => i18next.t(key);
    const [isCompanyCarFormPrepopulated, setIsCompanyCarFormPrepopulated] =
        useState(false);
    const validatedRegNo = form.state.validated.registrationNumber;
    const { data: user } = useGetUser('dealer');
    const account = user?.claims;
    const {
        data: car,
        error: getCarError,
        status: getCarStatus,
        isFetching: isFetcingCar,
    } = useGetCar(form.state.validated.registrationNumber);
    const { city } = useGetCity(form.state.validated.zipCode);

    useEffect(() => {
        if (isCompanyCarFormPrepopulated) return;
        if (!account) return;
        if (form.inputProps('email').value !== '') return;
        if (form.inputProps('lastName').value !== '') return;
        if (form.inputProps('firstName').value !== '') return;

        form.inputProps('email').setValue(account.email);
        const name = account.name.split(' ');

        const lastName = name.pop();
        lastName && form.inputProps('lastName').setValue(lastName.toString());

        const firstName = name.join(' ');
        form.inputProps('firstName').setValue(firstName.toString());
        setIsCompanyCarFormPrepopulated(true);
    }, [account, isCompanyCarFormPrepopulated, form]);

    const selectedDealer = () => {
        const selectedDealerNumber = form.inputProps('dealerNumber').value;
        return dealers?.find((d) => d.number === selectedDealerNumber);
    };

    const carFetchedWithSvvData = getCarStatus === 'success' && !!car;
    const carFetchedWithoutSvvData = getCarStatus === 'success' && !car;

    return (
        <FormProvider
            id="requestForm"
            form={form}
            onSubmit={(data) =>
                !isFetcingCar &&
                !getCarError &&
                handleOnSubmit(data, selectedDealer())
            }
            submitOnEnter={true}
        >
            <SectionTitle>{t('carSection')}</SectionTitle>
            <InputLayout>
                <TextField
                    field="registrationNumber"
                    label={t('regNo')}
                    helperText={
                        carFetchedWithSvvData ? `${car.brand} ${car.model}` : ''
                    }
                    validity={
                        carFetchedWithSvvData
                            ? {
                                  type: 'success',
                                  message: `${car?.brand} ${car?.model}`,
                              }
                            : getCarError && validatedRegNo
                            ? {
                                  type: 'error',
                                  message: i18next.t('invalidCarMessage'),
                              }
                            : undefined
                    }
                />
                <TextField
                    field="mileage"
                    label={t('mileage')}
                    suffix={t('mileageSuffix')}
                    inputMode="numeric"
                    helperText={i18next.t('mileageHelpText')}
                />
                <div>
                    {i18next.t('addOptionsPart1')}
                    <StyledUl>
                        <li>{i18next.t('addOptionsPart2')}</li>
                        <li>{i18next.t('addOptionsPart3')}</li>
                        <li>{i18next.t('addOptionsPart4')}</li>
                        <li>{i18next.t('addOptionsPart5')}</li>
                        <li>{i18next.t('addOptionsPart6')}</li>
                    </StyledUl>
                </div>
                <TextArea
                    field="comment"
                    label={t('addOptionsTextFieldLabel')}
                    maxLength={300}
                    counterText={characterCount(
                        form?.state?.raw?.comment || '',
                        300
                    )}
                />
            </InputLayout>
            <SectionTitle>{t('customerSectionInCompanyForm')}</SectionTitle>
            <CompactInputLayout>
                <TextField
                    field="firstName"
                    label={t('firstName')}
                    autoComplete="given-name"
                />
                <TextField
                    field="lastName"
                    label={t('lastName')}
                    autoComplete="family-name"
                />
            </CompactInputLayout>
            <InputLayout>
                <TextField
                    field="phoneNumber"
                    label={t('phoneNumber')}
                    prefix="+47"
                    helperText={t('phoneHelpText')}
                    type="tel"
                    inputMode="tel"
                    autoComplete="tel"
                />
                <TextField
                    field="email"
                    label={t('email')}
                    type="email"
                    autoComplete="email"
                    inputMode="email"
                />
                <TextField
                    field="zipCode"
                    label={t('zipCode')}
                    inputMode="numeric"
                    autoComplete="postal-code"
                    suffix={city}
                />
                <span className="hidden-mobile" />
            </InputLayout>
            <DealerSectionTitle>
                {t('chooseDealerCompanyCar')}
            </DealerSectionTitle>
            <CompanyCarInfo>{i18next.t('companyCarInfo')}</CompanyCarInfo>
            <InputLayout>
                <SingleSelect
                    field="dealerNumber"
                    label={t('chooseDealer')}
                    options={
                        dealers
                            ? [
                                  { value: '', label: '' },
                                  ...sortedDealers(dealers).map((dealer) => ({
                                      value: dealer.number,
                                      label: dealer.name,
                                  })),
                              ]
                            : []
                    }
                />
                <TextField
                    field="desiredDeliveryDate"
                    label={t('pickDeliveryDate')}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    type="date"
                />
            </InputLayout>
            <div>
                <DealerSectionTitle>
                    {t('onDeliveryRememberCompanyCarHeader')}
                </DealerSectionTitle>
                <StyledUl>
                    <li>{i18next.t('onDeliveryRememberCompanyCarBullet1')}</li>
                    <li>{i18next.t('onDeliveryRememberCompanyCarBullet2')}</li>
                    <li>{i18next.t('onDeliveryRememberCompanyCarBullet3')}</li>
                </StyledUl>
            </div>
            <SubmitLayout>
                {carFetchedWithoutSvvData && <SvvDownAlertMessage />}
                <PrimaryButton type="submit">{t('sendRequest')}</PrimaryButton>
            </SubmitLayout>
        </FormProvider>
    );
};

import { ONE_TIME_CODE } from '@/http/endpoints';
import { put } from '@/http/put';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

type VerifyOneTimeCodeError = {
    incorrectCode?: boolean;
    hasReachedRetryLimit?: boolean;
};

type OneTimeCodeResponse = {
    authToken: string;
};

export const useVerifyOneTimeCode = (
    phoneNumber: string | undefined,
    code: string | undefined,
    onSuccess: (data: OneTimeCodeResponse) => void
) => {
    const verifyOneTimeCode = () =>
        put<OneTimeCodeResponse, unknown>(ONE_TIME_CODE, {
            phoneNumber,
            code,
        }).request.then((res) => res.data);

    return useMutation({
        mutationFn: () => verifyOneTimeCode(),
        onSuccess: (data: OneTimeCodeResponse) => onSuccess(data),
        onError: (error: AxiosError<VerifyOneTimeCodeError>) => error,
    });
};

import { ZIP_CODES } from '@/http/endpoints';
import { get } from '@/http/get';
import format from 'string-format';

export type CityPayload = {
    city: string;
    code: string;
};

export const getCity = (zipCode: string) => {
    return get<CityPayload>(format(ZIP_CODES, zipCode)).request;
};

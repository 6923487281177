import { AlertBanner } from '@moller/design-system';
import { styled } from 'styled-components';
import { useFeatureFlags } from '@/utils/feature-toggle/useFeatureFlags';

export const Alert = styled(AlertBanner)`
    margin-bottom: var(--moller-spacing-xl);
`;

export const AlertMessage = () => {
    const { alertMessage } = useFeatureFlags();

    if (alertMessage && alertMessage.message)
        return (
            <Alert
                message={alertMessage.message}
                heading={alertMessage.heading || ''}
                type={alertMessage.type || 'info'}
            />
        );
    else return <></>;
};

import { styled } from 'styled-components';
import { InfoCard } from '@moller/design-system';
import i18next from 'i18next';
import { numberFormat } from '@/utils/numberFormat';
import {
    CardSubTitle,
    CardSubTitlePrefix,
    CardTitle,
} from './PriceEstimateContent';
import { Collapse } from '@/components/Collapse';

const Card = styled(InfoCard)`
    background-color: var(--moller-color-surface-variant);
    border-color: var(--moller-color-surface-variant);
    margin-bottom: var(--moller-spacing-l);
    color: var(--moller-color-primary);
    padding: var(--moller-spacing-xs) var(--moller-spacing-l);
`;

export const MarketPriceCard = ({
    marketMinPrice,
    marketMaxPrice,
}: {
    marketMinPrice: number;
    marketMaxPrice: number;
}) => {
    return (
        <Card>
            <CardTitle>{i18next.t('marketPrice')}</CardTitle>
            <CardSubTitlePrefix>
                {i18next.t('similarCarsSoldFor')}
            </CardSubTitlePrefix>
            <CardSubTitle>{`${numberFormat(marketMinPrice)} - ${numberFormat(
                marketMaxPrice
            )} kr`}</CardSubTitle>
            <Collapse
                id="market-price-card-content"
                backgroundColorVariable="--moller-color-surface-variant"
                collapsedHeight="0"
            >
                <p>{i18next.t('marketPriceInfo')}</p>
            </Collapse>
        </Card>
    );
};

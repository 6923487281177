import { LinkCard } from '@moller/design-system';
import { PATH_SELF_DECLARATION } from '@/utils/url/routes';
import { useParams } from 'react-router';
import { useGetMyPageValuation } from '../queries/useGetMyPageValuation';
import i18next from 'i18next';
import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';
import { LinkCardContent } from '@/components/LinkCardContent';
import { Link } from 'react-router-dom';

export const SelfDeclarationCard = ({ className }: { className?: string }) => {
    const { id } = useParams<{ id: string }>();
    const { data: valuation } = useGetMyPageValuation(id);
    const selfDeclarationIsFilledOut = !!valuation?.selfDeclaration;

    useEffect(() => {
        if (valuation && !selfDeclarationIsFilledOut) {
            datadogRum.addAction('Add self declaration card visible');
        }
    }, [valuation, selfDeclarationIsFilledOut]);

    if (valuation) {
        return (
            <LinkCard
                className={className}
                key={valuation.valuationId}
                $as={Link}
                to={`${PATH_SELF_DECLARATION}/${valuation.valuationId}`}
            >
                <LinkCardContent
                    title={i18next.t(
                        selfDeclarationIsFilledOut
                            ? 'seeSelfDeclaration'
                            : 'selfDeclarationCardTitle'
                    )}
                    description={i18next.t('selfDeclarationCardInfo')}
                />
            </LinkCard>
        );
    } else return <></>;
};

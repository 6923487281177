import { PropsWithChildren } from 'react';
import { AuthType, useAuth, useGetUser } from './AuthContext';

interface AuthViewInterface {
    readonly authType: AuthType;
}

export const AuthView = ({
    children,
    authType,
}: PropsWithChildren<AuthViewInterface>) => {
    const { login } = useAuth();
    const { data, error } = useGetUser(authType);
    if (error) login(authType);

    if (data?.isAuthenticated) {
        return <>{children}</>;
    }
    return <></>;
};

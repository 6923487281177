import {
    formInputs,
    FormProvider,
    useLocalFormState,
    PrimaryButton,
} from '@moller/design-system';
import {
    ExpectationsFormModel,
    expectationsFormValidator,
    initialExpectationsFormValues,
} from '@/views/expectations-view/utils/expectationsFormUtils';
import i18next from 'i18next';
import { styled } from 'styled-components';
import { SimilarCarsWrapper } from '@/views/expectations-view/components/SimilarCarsWrapper';
import { usePostExpectations } from '@/views/expectations-view/queries/usePostExpectations';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import { GeneralErrorAlert } from '@/components/GeneralErrorAlert';
import { LoadingOverlay } from '@/components/loading/LoadingOverlay';
import { datadogRum } from '@datadog/browser-rum';

const { TextField, TextArea } = formInputs<ExpectationsFormModel>();

const TextAreaWrapper = styled.div`
    padding-top: var(--moller-spacing-xxs);
    width: 100%;

    > span {
        width: 100%;
    }
`;

export const SubmitButton = styled(PrimaryButton)`
    margin: var(--moller-spacing-xxs) 0 var(--moller-spacing-3xl);
    width: 30%;
`;

export const ExpectationsForm = ({
    setIsSuccess,
}: {
    setIsSuccess: (isSuccess: boolean) => void;
}) => {
    const form = useLocalFormState<ExpectationsFormModel>(
        initialExpectationsFormValues,
        expectationsFormValidator
    );
    const { id } = useParams<{ id: string }>();
    const postExpectations = usePostExpectations(id);
    const { isSuccess, isError, isLoading } = postExpectations;

    useEffect(() => {
        if (isSuccess) {
            setIsSuccess(true);
            datadogRum.addAction('Expectations success form submit');
        }
    }, [isSuccess, setIsSuccess]);

    return (
        <FormProvider
            id="expectationsForm"
            form={form}
            hideNecessityText
            onSubmit={(data) => postExpectations.mutate(data)}
            submitOnEnter
        >
            {isLoading && <LoadingOverlay fixed delayMillis={0} />}
            <SimilarCarsWrapper
                secondItemElement={
                    <TextField
                        field="similarCarUrl"
                        label={i18next.t('similarCarUrlLabel')}
                    />
                }
            />
            <TextAreaWrapper>
                <TextArea
                    field="comment"
                    label={i18next.t('expectationsCommentLabel')}
                />
            </TextAreaWrapper>
            <SubmitButton type="submit">{i18next.t('send')}</SubmitButton>
            {isError && <GeneralErrorAlert />}
        </FormProvider>
    );
};

import { MY_VALUATIONS } from '@/http/endpoints';
import { get } from '@/http/get';
import { useQuery } from '@tanstack/react-query';
import { CarModel } from '@/types/CarModel';

export interface MyPageOverviewModel {
    readonly id: number;
    readonly car: CarModel;
}

export const useGetMyPage = () => {
    const getMyPage = () =>
        get<MyPageOverviewModel[]>(MY_VALUATIONS).request.then(
            (res) => res.data
        );

    return useQuery({
        queryKey: ['myPage'],
        queryFn: getMyPage,
    });
};

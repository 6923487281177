import {
    matchesRegex,
    validation,
    ValidationStepResult,
} from '@moller/design-system/utilities/validation';

const VALID_URL = new RegExp(
    '((http|https)://)(www.)?[a-zA-Z0-9@:%._+~#?&/=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._+~#?&/=]*)'
);

export const matchesUsedCarSalesUrls =
    () =>
    (value: string): ValidationStepResult => ({
        isValid:
            value.includes('finn.no') ||
            value.includes('bruktbil.no') ||
            value.includes('bruktbil.mollerbil.no'),
        errorMessage:
            'Url-en må være fra enten finn.no, bruktbil.no eller bruktbil.mollerbil.no',
    });

export const isValidUrl = (
    extraStep: (value: string) => ValidationStepResult
) =>
    validation({
        steps: [
            matchesRegex(
                VALID_URL,
                'Url-en er ikke gyldig. Eksempel: http://www.example.com'
            ),
            extraStep,
        ],
    });

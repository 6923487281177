import { CarInfo } from './CarInfo';
import { MyPageValuationModel } from '@/types/MyPageValuationModel';
import { styled } from 'styled-components';
import { SelfDeclarationCard } from './SelfDeclarationCard';
import { ImagesCard } from './ImagesCard';
import i18next from 'i18next';
import { PriceEstimateCard } from './PriceEstimateCard';
import { ExpectationsCard } from '@/views/my-page/components/ExpectationsCard';

const CardsWrapper = styled.div`
    > a {
        margin-top: var(--moller-spacing-xs);
    }
`;

const Description = styled.p`
    margin-bottom: var(--moller-spacing-s);
`;

export const CarStepContent = ({
    valuation,
}: {
    valuation: MyPageValuationModel;
}) => {
    return (
        <div>
            <Description>{i18next.t('carStepInfo')}</Description>
            {valuation?.car && (
                <CarInfo car={valuation.car} comment={valuation.comment} />
            )}
            <CardsWrapper>
                <PriceEstimateCard />
                {valuation.expectations?.isEnabled && <ExpectationsCard />}
                <ImagesCard />
                <SelfDeclarationCard />
            </CardsWrapper>
        </div>
    );
};

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MY_VALUATION_CONTRACT_STATUS } from '@/http/endpoints';
import { post } from '@/http/post';
import format from 'string-format';

export const useSendContractSigningStatus = (id: string | undefined) => {
    const sendContractSignedCallback = (status: 'closed' | 'rejected') =>
        post(format(MY_VALUATION_CONTRACT_STATUS, id || ''), {
            document_json: {
                status,
            },
        }).request;
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (status: 'closed' | 'rejected') =>
            sendContractSignedCallback(status),
        onSuccess: () => {
            void queryClient.invalidateQueries({
                queryKey: ['myPageValuation', id],
            });
        },
    });
};

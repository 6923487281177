import { getCurrentEnvironmentName } from '@/http/utils/getCurrentEnvironment';
import TagManager from 'react-gtm-module';

export const initializeGTM = () => {
    const envName = getCurrentEnvironmentName();
    const isProd = envName === 'prod';
    const gtmProdPreview = 'env-1';
    const gtmTestPreview = 'env-11';
    const gtmProdAuth = 'bzuikHhoFN8Rkh8Dz9evZQ';
    const gtmTestAuth = 'vw4-H524ZWnAAb1DdFnqxQ';

    const tagManagerArgs = {
        gtmId: 'GTM-T5QZHVHL',
        auth: isProd ? gtmProdAuth : gtmTestAuth,
        preview: isProd ? gtmProdPreview : gtmTestPreview,
        events: {
            eventName: 'consumerFormSubmitted',
        },
    };

    TagManager.initialize(tagManagerArgs);
};

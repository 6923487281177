import { useParams } from 'react-router';
import { PATH_MY_PAGE } from '@/utils/url/routes';
import { Link, useNavigate } from 'react-router-dom';
import { PageHeader } from '@/components/PageHeader';
import { SelfDeclarationForm } from './components/SelfDeclarationForm';
import { usePostSelfDeclaration } from './queries/usePostSelfDeclaration';
import { LoadingOverlay } from '@/components/loading/LoadingOverlay';
import { GeneralErrorAlert } from '@/components/GeneralErrorAlert';
import { styled } from 'styled-components';
import i18next from 'i18next';
import { Banner } from '../my-page-contract-signed/MyPageContractSigned';
import { useGetMyPageValuation } from '../my-page/queries/useGetMyPageValuation';
import { SelfDeclaration } from './components/SelfDeclaration';
import { Page } from '@/components/Page';
import { Divider } from '@/components/Divider';
import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { PrimaryButton, TextButton } from '@moller/design-system';
import { ButtonsWrapper } from '@/views/my-page-contract/components/MyPageContractForm';
import { PowerTrain } from '@/utils/enums/PowerTrain';

const GeneralError = styled(GeneralErrorAlert)`
    margin-top: var(--moller-spacing-xl);
`;

const QuestionsInfo = styled.p`
    margin-bottom: var(--moller-spacing-xl);
`;

const Buttons = styled(ButtonsWrapper)`
    margin-top: var(--moller-spacing-l);
`;

export const SelfDeclarationView = () => {
    const { id } = useParams<{ id: string }>();
    const { data: valuation } = useGetMyPageValuation(id);
    const navigate = useNavigate();
    const postSelfDeclaration = usePostSelfDeclaration(id);
    const { isError, isLoading, isSuccess } = postSelfDeclaration;

    useEffect(() => {
        if (isSuccess) {
            datadogRum.addAction('Self declaration success form submit');
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            datadogRum.addAction('Self declaration error form submit');
        }
    }, [isError]);

    return (
        <>
            {id && (
                <PageHeader
                    titleTextKey="selfDeclaration"
                    backButtonOnClick={() => navigate(`${PATH_MY_PAGE}/${id}`)}
                    backButtonTextKey="toFrontpage"
                />
            )}
            <Page>
                {isLoading && <LoadingOverlay fixed delayMillis={0} />}
                {isSuccess ? (
                    <>
                        <Banner
                            type="success"
                            message={i18next.t('selfDeclarationSuccess')}
                        />
                        <PrimaryButton
                            style={{ alignSelf: 'flex-end' }}
                            $as={Link}
                            to={`${PATH_MY_PAGE}/${id || ''}`}
                        >
                            {i18next.t('goToFrontpage')}
                        </PrimaryButton>
                    </>
                ) : valuation?.selfDeclaration ? (
                    <>
                        <SelfDeclaration
                            selfDeclaration={valuation.selfDeclaration}
                        />
                        <Divider />
                        <QuestionsInfo>
                            {i18next.t('selfDeclarationQuestionsInfo')}
                        </QuestionsInfo>
                    </>
                ) : (
                    <SelfDeclarationForm
                        onSubmit={(data) => postSelfDeclaration.mutate(data)}
                        selfDeclaration={valuation?.selfDeclaration}
                        isElectric={
                            valuation?.car?.powerTrain === PowerTrain.ELECTRIC
                        }
                        formBottomContent={
                            <Buttons>
                                {id && (
                                    <TextButton
                                        type="button"
                                        onClick={() =>
                                            navigate(`${PATH_MY_PAGE}/${id}`)
                                        }
                                    >
                                        {i18next.t('cancel')}
                                    </TextButton>
                                )}
                                <PrimaryButton type="submit">
                                    {i18next.t('saveAndSend')}
                                </PrimaryButton>
                            </Buttons>
                        }
                    />
                )}
                {isError && <GeneralError />}
            </Page>
        </>
    );
};

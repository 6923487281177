import { useQuery } from '@tanstack/react-query';
import format from 'string-format';
import { VEHICLES } from '@/http/endpoints';
import { get } from '@/http/get';

interface CarPayload {
    brand: string;
    model: string;
    isElectric: boolean;
}

export const useGetCar = (registrationNumber: string | undefined) => {
    const getCarInfo = () =>
        get<CarPayload>(
            format(VEHICLES, registrationNumber || '')
        ).request.then((res) => res.data);

    return useQuery({
        queryKey: ['car', registrationNumber],
        queryFn: getCarInfo,
        enabled: !!registrationNumber,
    });
};

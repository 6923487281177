import { styled } from 'styled-components';
import i18next from 'i18next';
import { Page as PageComponent } from './Page';
import { Icon, AllowedIcons, breakpointDesktop } from '@moller/design-system';

export const Page = styled(PageComponent)`
    padding: 0 0 var(--moller-spacing-l);
    > div {
        padding: 96px var(--moller-spacing-xxl);
        display: flex;
        align-items: center;

        p {
            text-align: center;
        }
    }
`;

const Title = styled.h1`
    font-weight: 500;
    margin: var(--moller-spacing-xxl) 0 var(--moller-spacing-xs);
    line-height: var(--moller-typeface-line-height);
    text-align: center;
    font-size: var(--moller-typeface-size-xl);

    @media screen and (min-width: ${breakpointDesktop}) {
        font-size: var(--moller-typeface-size-xxl);
    }
`;

const ErrorIcon = styled(Icon)`
    width: fit-content;
    height: fit-content;
    svg {
        width: var(--moller-size-3xl);
        height: var(--moller-size-3xl);
    }
`;

interface ErrorPageProps {
    readonly titleTextKey?: string;
    readonly descriptionTextKey?: string;
    readonly icon?: AllowedIcons;
}

export const ErrorPage = ({
    titleTextKey = 'errorPageTitle',
    descriptionTextKey = 'errorPageDescription',
    icon = 'car_crash',
}: ErrorPageProps) => {
    return (
        <Page>
            <ErrorIcon icon={icon} />
            <Title>{i18next.t(titleTextKey)}</Title>
            <p>{i18next.t(descriptionTextKey)}</p>
        </Page>
    );
};

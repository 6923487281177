import {
    ProgressBar,
    Step,
} from '@/views/my-page-contract/components/ProgressBar';
import { ReactElement, useEffect, useState } from 'react';
import { CarStep, CarStepFormModel, initialCarStepFormValues } from './CarStep';
import { FormHeadline } from '@/views/my-page-contract/MyPageContract';
import i18next from 'i18next';
import { styled } from 'styled-components';
import { breakpointTablet } from '@moller/design-system/utilities/layout';
import { PhoneNumberStep } from './PhoneNumberStep';
import { RejectionPageContent } from '../RejectedPageContent';
import { RejectionType } from '@/utils/enums/RejectionType';
import { OneTimeCodeStep } from './OneTimeCodeStep';
import {
    CustomerStep,
    CustomerStepFormModel,
    initialCustomerStepFormValues,
} from './CustomerStep';
import { DealerModel } from '@/types/DealerModel';
import { GeneralErrorAlert } from '@/components/GeneralErrorAlert';
import { datadogRum } from '@datadog/browser-rum';
import { AlertMessage } from '../AlertMessage';
import { useGetUrlParams } from '../../utils/useGetUrlParams';
import { RequestModel } from '../../queries/useConfirmRequest';
import { SelfDeclarationStep } from '@/views/valuation-request-view/components/valuation-form/SelfDeclarationStep';
import { SelfDeclarationModel } from '@/types/SelfDeclarationModel';

export enum ValuationFormStep {
    CAR = 'CAR',
    SELF_DECLARATION = 'SELF_DECLARATION',
    PHONE_NUMBER = 'PHONE_NUMBER',
    ONE_TIME_CODE = 'ONE_TIME_CODE',
    CUSTOMER = 'CUSTOMER',
}

interface StepContent {
    title: string;
    component: ReactElement;
}

export const steps: Step[] = [
    ValuationFormStep.CAR,
    ValuationFormStep.SELF_DECLARATION,
    ValuationFormStep.PHONE_NUMBER,
    ValuationFormStep.ONE_TIME_CODE,
    ValuationFormStep.CUSTOMER,
];

const FormWrapper = styled.div`
    padding: 0;
    position: relative;

    @media screen and (min-width: ${breakpointTablet}) {
        padding: var(--moller-spacing-l) 0;
    }
`;

interface ValuationFormProps {
    dealers: DealerModel[] | undefined;
    handleOnSubmit: (
        data: RequestModel,
        selectedDealer?: DealerModel,
        authToken?: string
    ) => void;
    isError: boolean;
    setCarModel: (carModel: string) => void;
}

export const ValuationForm = ({
    dealers,
    handleOnSubmit,
    isError,
    setCarModel,
}: ValuationFormProps) => {
    const { regNoFromUrl } = useGetUrlParams();
    const [rejectionReasonType, setRejectionReasonType] = useState<
        RejectionType | undefined
    >(undefined);
    const [car, setCar] = useState<CarStepFormModel>(initialCarStepFormValues);
    const [customer, setCustomer] = useState<CustomerStepFormModel>(
        initialCustomerStepFormValues
    );
    const [selfDeclaration, setSelfDeclaration] =
        useState<SelfDeclarationModel>();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [authToken, setAuthToken] = useState('');
    const [oneTimeCode, setOneTimeCode] = useState('');
    const [activeStep, setActiveStep] = useState(steps[0]);
    const [isElectric, setIsElectric] = useState(false);
    const indexOfActiveStep = steps.indexOf(activeStep);
    const goToNextStep = () => setActiveStep(steps[indexOfActiveStep + 1]);
    const goToPreviousStep = () => setActiveStep(steps[indexOfActiveStep - 1]);

    useEffect(() => {
        const activeStepActionInfo = () => {
            switch (activeStep) {
                case ValuationFormStep.CAR:
                    return 'Valuation form car step';
                case ValuationFormStep.SELF_DECLARATION:
                    return 'Valuation form selfDeclaration step';
                case ValuationFormStep.PHONE_NUMBER:
                    return 'Valuation form phoneNumber step';
                case ValuationFormStep.ONE_TIME_CODE:
                    return 'Valuation form oneTimeCode step';
                case ValuationFormStep.CUSTOMER:
                    return 'Valuation form customer step';
            }
            return '';
        };

        if (activeStep) {
            datadogRum.addAction(activeStepActionInfo());
        }
    }, [activeStep]);

    const stepContent = (step: string): StepContent => {
        switch (step) {
            case ValuationFormStep.CAR:
                return {
                    component: (
                        <>
                            <AlertMessage />
                            <CarStep
                                setRejectionReasonType={setRejectionReasonType}
                                goToNextStep={() => goToNextStep()}
                                setCar={setCar}
                                car={car}
                                setCarModel={setCarModel}
                                setIsElectric={setIsElectric}
                            />
                        </>
                    ),
                    title: regNoFromUrl
                        ? 'valuationFormExistingCarLabel'
                        : 'valuationFormCarLabel',
                };
            case ValuationFormStep.SELF_DECLARATION:
                return {
                    component: (
                        <SelfDeclarationStep
                            goToNextStep={() => goToNextStep()}
                            goToPreviousStep={() => goToPreviousStep()}
                            selfDeclaration={selfDeclaration}
                            setSelfDeclaration={setSelfDeclaration}
                            isElectric={isElectric}
                        />
                    ),
                    title: 'valuationFormSelfDeclarationLabel',
                };
            case ValuationFormStep.PHONE_NUMBER:
                return {
                    component: (
                        <PhoneNumberStep
                            goToPreviousStep={() => goToPreviousStep()}
                            goToNextStep={() => goToNextStep()}
                            setPhoneNumber={setPhoneNumber}
                            phoneNumber={phoneNumber}
                            authToken={authToken}
                        />
                    ),
                    title: 'valuationFormPhoneNumberLabel',
                };
            case ValuationFormStep.ONE_TIME_CODE:
                return {
                    component: (
                        <OneTimeCodeStep
                            goToPreviousStep={() => goToPreviousStep()}
                            goToNextStep={() => goToNextStep()}
                            phoneNumber={phoneNumber}
                            setAuthToken={setAuthToken}
                            authToken={authToken}
                            setOneTimeCode={setOneTimeCode}
                            oneTimeCode={oneTimeCode}
                        />
                    ),
                    title: 'valuationFormOneTimeCodeLabel',
                };
            case ValuationFormStep.CUSTOMER:
                return {
                    component: (
                        <CustomerStep
                            goToPreviousStep={() => goToPreviousStep()}
                            dealers={dealers}
                            handleOnSubmit={(customerData, selectedDealer) =>
                                handleOnSubmit(
                                    {
                                        ...customerData,
                                        ...car,
                                        phoneNumber,
                                        selfDeclaration,
                                    },
                                    selectedDealer,
                                    authToken
                                )
                            }
                            setCustomer={setCustomer}
                            customer={customer}
                        />
                    ),
                    title: 'valuationFormCustomerLabel',
                };
            default:
                return {
                    component: <></>,
                    title: '',
                };
        }
    };

    return (
        <FormWrapper>
            {rejectionReasonType ? (
                <RejectionPageContent rejectionType={rejectionReasonType} />
            ) : (
                <>
                    <ProgressBar steps={steps} activeStep={activeStep} />
                    <FormHeadline>
                        {i18next.t(stepContent(activeStep).title)}
                    </FormHeadline>
                    {stepContent(activeStep).component}
                    {isError && <GeneralErrorAlert />}
                </>
            )}
        </FormWrapper>
    );
};

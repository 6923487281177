import { FormButtons } from '../../valuation-request-view/components/valuation-form/FormButtons';
import { ImageModel } from '@/types/ImageModel';

interface SaveImagesProps {
    selectedImages: ImageModel[];
    onSaveClick: (images: ImageModel[]) => void;
    onCancelClick: () => void;
}

export const SaveImages = ({
    selectedImages,
    onSaveClick,
    onCancelClick,
}: SaveImagesProps) => {
    return (
        <FormButtons
            primaryAction={{
                textKey: 'save',
                onClick: () => onSaveClick(selectedImages),
            }}
            secondaryAction={{
                textKey: 'cancel',
                onClick: onCancelClick,
            }}
        />
    );
};

export enum ValuationStatus {
    NEW = 'New',
    VALUATING = 'Valuating',
    WAITING = 'Waiting',
    OFFER_SENT = 'OfferSent',
    ASSESSMENT = 'Assessment',
    CONTRACT_SENT = 'ContractSent',
    CONTRACT_SIGNED = 'ContractSigned',
    ACCEPTED = 'Accepted',
    DECLINED = 'Declined',
    PURCHASE_COMPLETED = 'PurchaseCompleted',
}

import {
    useLocalFormState,
    FormProvider,
    formInputs,
    TextButton,
} from '@moller/design-system';
import { required } from '@moller/design-system/utilities/validation';
import i18next from 'i18next';
import { FormButtons } from './FormButtons';
import { InputWrapper } from './CarStep';
import { formatPhoneNumber } from '@/utils/formatPhoneNumber';
import { Info } from './PhoneNumberStep';
import { styled } from 'styled-components';
import { useVerifyOneTimeCode } from '../../queries/useVerifyOneTimeCode';
import { LoadingOverlay } from '@/components/loading/LoadingOverlay';
import { FormErrorAlert } from './FormErrorAlert';
import { useRequestOneTimeCode } from '../../queries/useRequestOneTimeCode';

type OneTimeCodeStepFormModel = {
    oneTimeCode: string;
};

const { TextField } = formInputs<OneTimeCodeStepFormModel>();

export const RetryButton = styled(TextButton)`
    margin-top: var(--moller-spacing-s);
`;

interface OneTimeCodeStepProps {
    goToNextStep: () => void;
    goToPreviousStep: () => void;
    setAuthToken: (authToken: string) => void;
    phoneNumber: string;
    authToken: string;
    setOneTimeCode: (phoneNumber: string) => void;
    oneTimeCode: string;
}

export const OneTimeCodeStep = ({
    goToNextStep,
    goToPreviousStep,
    phoneNumber,
    setAuthToken,
    authToken,
    setOneTimeCode,
    oneTimeCode,
}: OneTimeCodeStepProps) => {
    const form = useLocalFormState<OneTimeCodeStepFormModel>(
        { oneTimeCode },
        {
            oneTimeCode: required(),
        }
    );
    const verifyOneTimeCode = useVerifyOneTimeCode(
        phoneNumber,
        form.state.validated.oneTimeCode,
        (data) => {
            goToNextStep();
            setAuthToken(data.authToken);
            setOneTimeCode(form.state.validated.oneTimeCode || '');
        }
    );
    const {
        isLoading: verifyIsLoading,
        error: verifyError,
        reset: resetVerify,
    } = verifyOneTimeCode;
    const retryRequestCode = useRequestOneTimeCode(phoneNumber);
    const {
        isLoading: retryIsLoading,
        error: retryError,
        isError: retryHasError,
        reset: resetRetry,
    } = retryRequestCode;

    const setErrorMessage = () => {
        const verifyErrorResponseData =
            verifyError?.isAxiosError && verifyError.response?.data;
        const retryErrorResponseData =
            retryError?.isAxiosError && retryError.response?.data;

        if (verifyErrorResponseData) {
            if (verifyErrorResponseData.hasReachedRetryLimit) {
                return 'hasReachedRetryLimitError';
            }
            if (verifyErrorResponseData.incorrectCode) {
                return 'incorrectCodeError';
            }
        }
        if (retryHasError) {
            if (
                retryErrorResponseData &&
                retryErrorResponseData.hasReachedFrequencyLimit
            ) {
                return 'hasReachedFrequencyLimitError';
            } else return 'requestOneTimeCodeGeneralError';
        }
        return 'verifyOneTimeCodeGeneralError';
    };

    return (
        <FormProvider
            id="oneTimeCodeStepForm"
            form={form}
            onSubmit={
                authToken
                    ? () => {
                          goToNextStep();
                      }
                    : () => {
                          resetRetry();
                          verifyOneTimeCode.mutate();
                      }
            }
            hideNecessityText
        >
            {(verifyIsLoading || retryIsLoading) && (
                <LoadingOverlay delayMillis={0} fixed />
            )}
            <Info>
                {i18next.t('oneTimeCodeInfo', {
                    phoneNumber: formatPhoneNumber(phoneNumber),
                })}
            </Info>
            <InputWrapper>
                <TextField
                    field="oneTimeCode"
                    label={i18next.t('oneTimeCode')}
                    helperText={i18next.t('oneTimeCodeHelper')}
                    inputMode="numeric"
                    autoComplete="one-time-code"
                    autoFocus
                    trailingIcon={authToken ? 'check' : undefined}
                    disabled={!!authToken}
                />
            </InputWrapper>
            {!authToken && (
                <RetryButton
                    underline
                    type="button"
                    onClick={() => {
                        resetVerify();
                        retryRequestCode.mutate();
                    }}
                >
                    {i18next.t('sendOneTimeCodeAgain')}
                </RetryButton>
            )}
            <FormButtons
                extraMargin
                primaryAction={{
                    textKey: 'next',
                }}
                secondaryAction={{
                    textKey: 'previous',
                    onClick: () => goToPreviousStep(),
                }}
            />
            {(verifyError || retryError) && (
                <FormErrorAlert textKey={setErrorMessage()} />
            )}
        </FormProvider>
    );
};

import {
    AlertBanner,
    breakpointDesktop,
    Icon,
    LinkCard,
} from '@moller/design-system';
import { PATH_PRICE_ESTIMATE } from '@/utils/url/routes';
import { useParams } from 'react-router';
import { useGetMyPageValuation } from '../queries/useGetMyPageValuation';
import i18next from 'i18next';
import { styled } from 'styled-components';
import { Link } from 'react-router-dom';
import { numberFormat } from '@/utils/numberFormat';

const Title = styled.h3`
    font-weight: 500;
    font-size: var(--moller-typeface-size-s);
    color: var(--moller-keycolor-tertiary);
    text-transform: uppercase;
    margin: 0 0 var(--moller-spacing-base);
`;

const OfferText = styled.p`
    font-size: var(--moller-typeface-size-s);
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    color: var(--moller-color-primary);

    > div {
        display: flex;
        align-items: center;
        margin-bottom: var(--moller-spacing-base);

        h4 {
            margin: 0 var(--moller-spacing-xxs) 0 0;
            font-weight: 500;
            font-size: var(--moller-typeface-size-m);
            line-height: var(--moller-typeface-line-height);

            @media screen and (min-width: ${breakpointDesktop}) {
                font-size: var(--moller-typeface-size-l);
            }
        }
    }

    svg path {
        fill: var(--moller-color-primary);
    }
`;

export const TextContent = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledAlertBanner = styled(AlertBanner)`
    margin-block: var(--moller-spacing-xs);
`;

export const PriceEstimateCard = () => {
    const { id } = useParams<{ id: string }>();
    const { data: valuation } = useGetMyPageValuation(id);

    const isMileageUpdatedSinceLastEstimate =
        valuation?.priceEstimate?.externalEstimateDeviation;

    if (valuation && valuation.priceEstimate) {
        return (
            <LinkCard
                $as={Link}
                to={`${PATH_PRICE_ESTIMATE}/${valuation.valuationId}`}
            >
                <Content>
                    <Title>{i18next.t('priceEstimate')}</Title>
                    <OfferText>{i18next.t('weThinkWeCanOffer')}</OfferText>
                    <div>
                        <h4>
                            {i18next.t('fromToPrice', {
                                minPrice: numberFormat(
                                    valuation.priceEstimate.offerMinPrice
                                ),
                                maxPrice: numberFormat(
                                    valuation.priceEstimate.offerMaxPrice
                                ),
                            })}
                        </h4>
                        <Icon icon="arrow_forward" />
                    </div>
                    <TextContent>
                        {isMileageUpdatedSinceLastEstimate && (
                            <StyledAlertBanner
                                type="info"
                                density="compact"
                                message={i18next.t(
                                    'PriceEstimateCardInfoDeviation'
                                )}
                            />
                        )}
                        <p>{i18next.t('priceEstimateCardInfo')}</p>
                    </TextContent>
                </Content>
            </LinkCard>
        );
    } else return <></>;
};

import { DealerModel } from '@/types/DealerModel';

export const sortedDealers = (dealers: DealerModel[]) => {
    const array = dealers.slice();
    array.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        return 0;
    });
    return array;
};

import { getCurrentEnvironmentName } from '@/http/utils/getCurrentEnvironment';
import { styled } from 'styled-components';
import { useEffect, useState } from 'react';
import { get } from '@/http/get';
import { breakpointDesktop } from '@moller/design-system';

const Badge = styled.p`
    display: flex;
    position: absolute;
    left: 100px;
    align-self: center;
    font-weight: 500;
    background-color: var(--moller-palette-warning-50);
    font-size: var(--moller-typeface-size-xs);
    padding: 0 var(--moller-spacing-xxs);
    margin: 0 var(--moller-spacing-xs);
    border-radius: 20px;
    font-family: var(--moller-typeface-brand);
    @media screen and (min-width: ${breakpointDesktop}) {
        font-size: var(--moller-typeface-size-base);
        padding: var(--moller-spacing-base) var(--moller-spacing-s);
        left: 110px;
    }
`;

const Version = styled.span`
    padding-left: var(--moller-spacing-base);
    font-weight: 400;

    @media screen and (min-width: ${breakpointDesktop}) {
        padding-left: var(--moller-spacing-xs);
    }
`;

export const EnvBadge = () => {
    const envName = getCurrentEnvironmentName();
    const isNotProd = envName !== 'prod';
    const [apiVersion, setApiVersion] = useState('');

    useEffect(() => {
        if (!apiVersion && isNotProd) {
            void get<string>('').request.then(({ data }) => {
                const versionFromData = data?.split(' ')[1];
                setApiVersion(versionFromData);
            });
        }
    }, [apiVersion, isNotProd]);

    if (isNotProd) {
        return (
            <Badge>
                {envName.toUpperCase()}
                {apiVersion && <Version>{apiVersion}</Version>}
            </Badge>
        );
    }
    return <></>;
};

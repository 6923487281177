import { MY_VALUATION } from '@/http/endpoints';
import { get } from '@/http/get';
import { useQuery } from '@tanstack/react-query';
import { MyPageValuationModel } from '@/types/MyPageValuationModel';
import format from 'string-format';

export const useGetMyPageValuation = (id: string | undefined) => {
    const getMyPageValuation = () =>
        get<MyPageValuationModel>(format(MY_VALUATION, id || '')).request.then(
            (res) => res.data
        );

    return useQuery({
        queryKey: ['myPageValuation', id],
        queryFn: getMyPageValuation,
        enabled: !!id,
    });
};

import { ContractFormModel } from '../utils/contractFormUtils';
import { styled } from 'styled-components';
import { LocalFormState } from '@moller/design-system/utilities/forms/useLocalFormState.js';
import i18next from 'i18next';
import { Icon, TextButton } from '@moller/design-system';
import { Step } from './ProgressBar';
import { formSteps as steps, MyPageContractStep } from '../MyPageContract';

interface FormErrorsProps {
    readonly form: LocalFormState<ContractFormModel>;
    readonly setActiveStep: (step: Step) => void;
}

const ErrorWrapper = styled.div`
    margin-top: var(--moller-spacing-xl);
    padding: var(--moller-spacing-s);
    background-color: #ffe1e1;
    border: 1px solid #cd1f00;
    border-radius: 2px;
`;

const ErrorHeader = styled.h2`
    margin: 0 0 var(--moller-spacing-s);
    font-size: var(--moller-typeface-size-base);
    font-weight: 500;
    display: flex;
    align-items: center;
`;

export const ErrorHeaderIcon = styled(Icon)`
    color: var(--moller-color-error);
    padding-right: var(--moller-spacing-xxs);
`;

const ErrorItem = styled.div`
    justify-content: space-between;
    padding: 0;
    font-size: var(--moller-typeface-size-s);
    display: flex;
`;

const ErrorItemText = styled.li`
    justify-content: space-between;
    font-size: var(--moller-typeface-size-s);
    display: list-item;
`;

const ErrorSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: var(--moller-spacing-s) 0 var(--moller-spacing-xxs);

    h3 {
        font-size: var(--moller-typeface-size-s);
        font-weight: 500;
        margin: 0;
    }
`;

const ErrorButton = styled(TextButton)`
    margin-top: var(--moller-spacing-xxs);
    margin-left: auto;

    span {
        border-bottom-color: var(--moller-color-on-background) !important;
    }
`;

export const FormErrors = ({ form, setActiveStep }: FormErrorsProps) => {
    const validatedFields = Object.keys(form.state?.validated);
    type ErrorField = { field: keyof ContractFormModel; value: boolean };
    const errors: ErrorField[] = Object.entries(form.state?.showError).map(
        ([field, value]): ErrorField => ({ field, value } as ErrorField)
    );
    const activeErrors: (keyof ContractFormModel)[] = errors
        .filter((e) => e.value && !validatedFields.includes(e.field))
        .map((e) => e.field);
    const fieldValidityMessage = (field: keyof ContractFormModel): string =>
        form.inputProps(field)?.validity?.message || '';
    const stepOneErrors = activeErrors.filter(
        (e) =>
            e.includes('firstName') ||
            e.includes('lastName') ||
            e.includes('phoneNumber') ||
            e.includes('email') ||
            e.includes('address') ||
            e.includes('zipCode') ||
            e.includes('city')
    );
    const stepTwoErrors = activeErrors.filter((e) =>
        e.includes('accountNumber')
    );

    const stepLabel = (step: string) => {
        switch (step) {
            case MyPageContractStep.CUSTOMER:
                return 'contractCustomerLabel';
            case MyPageContractStep.CAR:
                return 'contractCarLabel';
            case MyPageContractStep.PAYMENT:
                return 'contractPaymentLabel';
            case MyPageContractStep.SIGNING:
                return 'contractSigningLabel';
            default:
                return '';
        }
    };

    const errorMessagesByStep = (
        step: Step,
        stepErrors: (keyof ContractFormModel)[]
    ) => {
        return (
            stepErrors.length > 0 && (
                <>
                    <ErrorSection>
                        <h3>{i18next.t(stepLabel(step))}</h3>
                    </ErrorSection>
                    {stepErrors.map((field) => (
                        <ErrorItem key={field}>
                            <ul>
                                <ErrorItemText>
                                    <p>{`${i18next.t(
                                        field
                                    )} - ${fieldValidityMessage(field)}`}</p>
                                </ErrorItemText>
                            </ul>
                        </ErrorItem>
                    ))}
                    <ErrorButton onClick={() => setActiveStep(step)} underline>
                        {`${i18next.t('goToStep')} "${i18next.t(
                            stepLabel(step)
                        )}"`}
                    </ErrorButton>
                </>
            )
        );
    };

    if (activeErrors.length) {
        return (
            <ErrorWrapper>
                <ErrorHeader>
                    <ErrorHeaderIcon icon="error" />
                    {i18next.t('contractFormErrorHeader')}
                </ErrorHeader>
                {errorMessagesByStep(steps[0], stepOneErrors)}
                {errorMessagesByStep(steps[1], stepTwoErrors)}
            </ErrorWrapper>
        );
    } else {
        return <></>;
    }
};

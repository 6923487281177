import { NewCarBox } from '@/components/NewCarBox';
import { RejectionType } from '@/utils/enums/RejectionType';
import { NewCarBoxWrapper } from '../ValuationRequestView';
import { styled } from 'styled-components';
import i18next from 'i18next';
import { breakpointDesktop } from '@moller/design-system';

interface RejectionPageContentProps {
    readonly rejectionType: RejectionType;
}

const Wrapper = styled.div`
    margin: 0 var(--moller-spacing-xxs);

    @media screen and (min-width: ${breakpointDesktop}) {
        margin: 0;
    }
`;

const Title = styled.h1`
    font-size: var(--moller-typeface-size-xl);
    font-weight: 500;
    line-height: var(--moller-typeface-line-height);
    margin: 0;

    @media screen and (min-width: ${breakpointDesktop}) {
        font-size: var(--moller-typeface-size-xxl);
    }
`;

const Paragraph = styled.p`
    margin-top: var(--moller-spacing-l);
`;

const rejectionReason = (rejectionType: RejectionType) => {
    switch (rejectionType) {
        case RejectionType.MODEL: {
            return i18next.t('rejectedModelDescription');
        }
        case RejectionType.AGE: {
            return i18next.t('rejectionDescription', {
                reason: i18next.t('rejectionReasonAge'),
            });
        }
        case RejectionType.MILEAGE: {
            return i18next.t('rejectionDescription', {
                reason: i18next.t('rejectionReasonMileage'),
            });
        }
        default: {
            return '';
        }
    }
};

export const RejectionPageContent = ({
    rejectionType,
}: RejectionPageContentProps) => {
    return (
        <Wrapper>
            <Title>{i18next.t('rejectionTitle')}</Title>
            <Paragraph>{rejectionReason(rejectionType)}</Paragraph>
            <NewCarBoxWrapper>
                <NewCarBox />
            </NewCarBoxWrapper>
        </Wrapper>
    );
};

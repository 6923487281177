import i18next from 'i18next';
import { styled } from 'styled-components';
import { PageTitle } from '../MyPage';

export const ErrorMessage = styled.p`
    padding-bottom: var(--moller-size-4xl);
`;

export const ErrorContent = ({ errorType }: { errorType: string }) => {
    const errorTitle =
        errorType === 'unauthorized' ? 'errorUnauthorizedTitle' : 'errorTitle';
    const errorDescription =
        errorType === 'unauthorized'
            ? 'errorUnauthorizedDescription'
            : 'myPageError';

    return (
        <>
            <PageTitle>{i18next.t(errorTitle)}</PageTitle>
            <ErrorMessage>{i18next.t(errorDescription)}</ErrorMessage>
        </>
    );
};

import { useParams } from 'react-router';
import { useGetMyPageValuation } from '@/views/my-page/queries/useGetMyPageValuation';
import { SHARED_PURCHASER_EMAIL } from './emailUtils';

export const usePurchaserEmail = () => {
    const { id } = useParams<{ id: string }>();
    const { data: valuation } = useGetMyPageValuation(id);

    return { email: valuation?.purchaser?.email || SHARED_PURCHASER_EMAIL };
};

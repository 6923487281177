import { AlertBanner } from '@moller/design-system';
import { styled } from 'styled-components';
import i18next from 'i18next';

export const Alert = styled(AlertBanner)`
    margin-bottom: var(--moller-spacing-xl);
`;

export const SvvDownAlertMessage = () => (
    <Alert
        heading={i18next.t('svvDownAlertHeading')}
        message={i18next.t('svvDownAlertMessage')}
        type="warning"
    />
);

import { ContractFormModel } from '../utils/contractFormUtils';
import { formInputs } from '@moller/design-system';
import i18next from 'i18next';
import { InputLayout } from '../../valuation-request-view/components/CompanyCarForm';
import { styled } from 'styled-components';

const { TextField } = formInputs<ContractFormModel>();

const WideTextFieldWrapper = styled.div`
    width: 100%;

    & > span {
        width: 100%;
    }
`;

export const CustomerStep = () => {
    return (
        <InputLayout>
            <TextField field="firstName" label={i18next.t('firstName')} />
            <TextField field="lastName" label={i18next.t('lastName')} />
            <TextField field="phoneNumber" label={i18next.t('phoneNumber')} />
            <TextField field="email" label={i18next.t('email')} />
            <WideTextFieldWrapper>
                <TextField field="address" label={i18next.t('address')} />
            </WideTextFieldWrapper>
            <TextField field="zipCode" label={i18next.t('zipCode')} />
            <TextField field="city" label={i18next.t('city')} />
        </InputLayout>
    );
};

import {
    EnvironmentName,
    parseEnvironmentName,
} from '@/http/utils/environment';

enum EnvironmentClientKey {
    PROD = 'Bruktbilx2:prod.6ea879c54139a1e20c934db6a35e4d2fe25fe0fba3376fb9a65e5bff',
    STAGE = 'Bruktbilx2:stage.d3f9ca5d18da95c8e30f9aa5b738cafc0cc48007381a5acfe535acf4',
    TEST = 'Bruktbilx2:test.5491e551a9a28965cdf78870b32093b1fff03f738e8741ecdf5ed546',

    DEV = 'Bruktbilx2:dev.a4bd899e3a50a0051f152159e12aa96a848fdb78953594f984b985d8',
}

enum EnvironmentClientUrl {
    PROD = 'https://unleash-edge.prod.mollercloud.no/api/frontend',
    STAGE = 'https://unleash-edge.stage.mollercloud.no/api/frontend',
    TEST = 'https://unleash-edge.test.mollercloud.no/api/frontend',
    DEV = 'https://unleash-edge.dev.mollercloud.no/api/frontend',
}

type FeatureToggleClient = {
    key: EnvironmentClientKey;
    url: EnvironmentClientUrl;
};

const featureToggleClient = (
    env: EnvironmentName | string
): FeatureToggleClient => {
    switch (env) {
        case 'prod':
            return {
                key: EnvironmentClientKey.PROD,
                url: EnvironmentClientUrl.PROD,
            };
        case 'stage':
            return {
                key: EnvironmentClientKey.STAGE,
                url: EnvironmentClientUrl.STAGE,
            };
        case 'test':
            return {
                key: EnvironmentClientKey.TEST,
                url: EnvironmentClientUrl.TEST,
            };
        case 'dev':
            return {
                key: EnvironmentClientKey.DEV,
                url: EnvironmentClientUrl.DEV,
            };
        default:
            return {
                key: EnvironmentClientKey.DEV,
                url: EnvironmentClientUrl.DEV,
            };
    }
};

export const getFeatureToggleClient = (
    env: EnvironmentName
): FeatureToggleClient => {
    const environment = parseEnvironmentName(import.meta.env.VITE_BACKEND_ENV);

    if (env === 'local' && environment) {
        return featureToggleClient(environment);
    }
    return featureToggleClient(env);
};

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { getCurrentEnvironment } from '@/http/utils/getCurrentEnvironment';

export const initializeDatadog = () => {
    const { apiUrl, envName } = getCurrentEnvironment();

    if (envName === 'local') return;

    datadogLogs.init({
        clientToken: 'pubeaa2acec6a0e562ea8eeb53685bb24a5',
        site: 'datadoghq.eu',
        service: 'bruktbilx2-web',
        env: envName,
        version: import.meta.env.VITE_APP_VERSION,
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
    });

    datadogRum.init({
        applicationId: 'f0ce9ccf-8515-46f6-ab13-5cc0698692b1',
        clientToken: 'pubeaa2acec6a0e562ea8eeb53685bb24a5',
        site: 'datadoghq.eu',
        service: 'bruktbilx2-web',
        env: envName,
        version: import.meta.env.VITE_APP_VERSION,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask',
        allowedTracingUrls: [apiUrl],
        traceSampleRate: 100,
    });

    datadogRum.startSessionReplayRecording();
};

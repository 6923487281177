import { ErrorPage } from '@/components/ErrorPage';
import { getCurrentEnvironmentName } from '@/http/utils/getCurrentEnvironment';

export const DashboardRedirect = () => {
    const envName = getCurrentEnvironmentName();

    const bruktbilportalenUrl =
        envName === 'prod'
            ? 'https://bruktbilportalen.moller.no/'
            : envName === 'dev' || envName === 'test'
            ? `https://bruktbilportalen.${envName}.mollercloud.no/`
            : envName === 'local'
            ? 'http://localhost:3002'
            : false;

    if (!bruktbilportalenUrl)
        return (
            <ErrorPage
                icon="sentiment_very_dissatisfied"
                titleTextKey="pageNotFoundTitle"
                descriptionTextKey="pageNotFoundDescription"
            />
        );

    window.location.replace(bruktbilportalenUrl);

    return <p>Du sendes til Bruktbilportalen</p>;
};

import {
    noValidation,
    required,
    ValidatorMap,
    validCellPhoneNumber,
    validEmail,
    validRegistrationNumber,
    validZipCode,
} from '@moller/design-system/utilities/validation';

export type CompanyCarFormModel = {
    phoneNumber: string;
    email: string;
    registrationNumber: string;
    mileage: string;
    firstName: string;
    lastName: string;
    zipCode: string;
    dealerNumber: string;
    desiredDeliveryDate?: string;
    comment?: string;
    purchaseHelpDesired: boolean;
};

export const initialCompanyCarFormValues = {
    phoneNumber: '',
    email: '',
    registrationNumber: '',
    mileage: '',
    firstName: '',
    lastName: '',
    zipCode: '',
    dealerNumber: '',
    desiredDeliveryDate: '',
    comment: '',
    purchaseHelpDesired: false,
};

export const companyCarFormValidator = (
    dealerOptional: boolean
): ValidatorMap<CompanyCarFormModel> => ({
    phoneNumber: required(validCellPhoneNumber),
    email: required(validEmail),
    registrationNumber: required(validRegistrationNumber),
    mileage: required(),
    firstName: noValidation(),
    lastName: noValidation(),
    zipCode: required(validZipCode),
    dealerNumber: dealerOptional ? noValidation() : required(),
    desiredDeliveryDate: noValidation(),
    comment: noValidation(),
    purchaseHelpDesired: noValidation(),
});

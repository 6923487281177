import { css, styled } from 'styled-components';
import { Delayed } from './Delayed';
import { Spinner } from './Spinner';

const Overlay = styled.div<{ $fixed?: boolean }>`
    ${(props) => css`
        position: ${props.$fixed ? 'fixed' : 'absolute'};
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 100;
        background: rgba(255, 255, 255, 0.8);
        animation: loading-overlay-fade-in ease 0.5s 1;
        animation-fill-mode: forwards;

        @keyframes loading-overlay-fade-in {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
    `}
`;

export const LoadingOverlay = ({
    delayMillis,
    fixed = false,
}: {
    delayMillis?: number;
    fixed?: boolean;
}) => (
    <Delayed delayMillis={delayMillis}>
        <Overlay $fixed={fixed}>
            <Spinner delayMillis={delayMillis} />
        </Overlay>
    </Delayed>
);

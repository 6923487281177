import { styled } from 'styled-components';
import { PropsWithChildren } from 'react';
import { breakpointTablet } from '@moller/design-system/utilities/layout';

const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--moller-spacing-l) var(--moller-spacing-l)
        var(--moller-spacing-xl);

    @media screen and (min-width: ${breakpointTablet}) {
        padding: var(--moller-spacing-l) 0 var(--moller-spacing-xxl);
    }
`;

const PageContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;

    @media screen and (min-width: ${breakpointTablet}) {
        width: 80%;
        max-width: 800px;
    }
`;

export const Page = ({
    className,
    children,
}: PropsWithChildren<{ className?: string }>) => {
    return (
        <PageWrapper className={className}>
            <PageContent>{children}</PageContent>
        </PageWrapper>
    );
};

import {
    Environment,
    EnvironmentName,
    environmentNameFromLocation,
    getEnvironment,
} from './environment';

const defaultEnv = 'local';

export function getCurrentEnvironment(): Environment {
    const environmentName =
        environmentNameFromLocation(window.location) ?? defaultEnv;
    return getEnvironment(environmentName);
}

export function getCurrentEnvironmentName(): EnvironmentName {
    return getCurrentEnvironment().envName;
}

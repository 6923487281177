import { OfferModel, PriceOfferCustomerResponse } from '@/types/OfferModel';
import { Collapse } from '@/components/Collapse';
import { css, styled } from 'styled-components';
import {
    AlertBanner,
    breakpointDesktop,
    GhostButton,
    InfoCard,
    PrimaryButton,
} from '@moller/design-system';
import i18next from 'i18next';
import { numberFormat } from '@/utils/numberFormat';
import { AttachmentCard } from './AttachmentCard';
import { useParams } from 'react-router';
import { useGetMyPageValuation } from '../queries/useGetMyPageValuation';
import { useState } from 'react';
import { CustomerResponse } from './price-estimate/CustomerResponse';
import { useUpdatePriceOffer } from '../queries/useUpdatePriceOffer';
import { LoadingOverlay } from '@/components/loading/LoadingOverlay';
import { GeneralErrorAlert } from '@/components/GeneralErrorAlert';
import { ValuationStatus } from '@/utils/enums/ValuationStatus';
import { NetPromoterScore } from '@/components/net-promoter-score/NetPromoterScore';
import parse from 'html-react-parser';
import moment from 'moment';
import { DATE_MONTH_FORMAT } from '@/utils/dateUtils';
import { useFeatureFlags } from '@/utils/feature-toggle/useFeatureFlags';

const Card = styled(InfoCard)<{ $topMargin: boolean }>`
    ${(props) => css`
        border-color: var(--moller-color-outline);
        ${props.$topMargin && 'margin-top: var(--moller-spacing-s);'}
    `}
`;

const Label = styled.label<{ $isDisabled: boolean }>`
    ${(props) => css`
        display: flex;
        text-transform: uppercase;
        font-size: var(--moller-typeface-size-s);
        font-weight: 500;
        color: var(
            ${props.$isDisabled
                ? '--moller-color-on-disabled'
                : '--moller-color-primary'}
        );

        @media screen and (min-width: ${breakpointDesktop}) {
            font-size: var(--moller-typeface-size-s);
        }
    `}
`;

const PurchasePrice = styled.h2<{ $isDisabled: boolean }>`
    ${(props) => css`
        font-size: var(--moller-typeface-size-xxl);
        font-weight: 500;
        margin: 0 0 var(--moller-spacing-s);
        line-height: var(--moller-typeface-line-height);
        color: var(
            ${props.$isDisabled
                ? '--moller-color-on-disabled'
                : '--moller-color-primary'}
        );
    `}
`;

const StyledCollapse = styled(Collapse)<{ $isDisabled: boolean }>`
    ${(props) => css`
        ${props.$isDisabled &&
        css`
            > div {
                color: var(--moller-color-on-disabled);
            }
        `}
    `}
`;

const Justification = styled.p`
    white-space: pre-wrap;

    @media screen and (min-width: ${breakpointDesktop}) {
        width: 75%;
    }
`;

const Buttons = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: var(--moller-spacing-xs);
    padding-top: var(--moller-spacing-l);

    @media screen and (min-width: ${breakpointDesktop}) {
        flex-direction: row;
        column-gap: var(--moller-spacing-xs);
    }

    button {
        width: 100%;
    }
`;

const ExpiredContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--moller-spacing-xs);
    padding-top: var(--moller-spacing-l);

    button {
        width: 100%;
    }
`;

const Attachments = styled.div`
    display: flex;
    width: 100%;
    row-gap: var(--moller-spacing-s);
    column-gap: var(--moller-spacing-s);
    padding-top: var(--moller-spacing-s);
    flex-wrap: wrap;
`;

const Response = styled(CustomerResponse)`
    width: 100%;
`;

const DeadlineBanner = styled(AlertBanner)`
    margin-top: var(--moller-spacing-m);
`;

const Error = styled(GeneralErrorAlert)`
    margin: var(--moller-spacing-l) 0 0;
`;

interface PriceOfferProps {
    offer: OfferModel;
}

export const PriceOffer = ({ offer }: PriceOfferProps) => {
    const { id } = useParams<{ id: string }>();
    const { data: valuation } = useGetMyPageValuation(id);
    const [customerResponse, setCustomerResponse] = useState<
        PriceOfferCustomerResponse | undefined
    >(valuation?.offer?.customerResponse);
    const updatePriceOffer = useUpdatePriceOffer(id, offer.id);
    const { isError, isLoading, isSuccess } = updatePriceOffer;
    const attachment = valuation?.offer.attachment;
    const isExpired = customerResponse === 'Expired';
    const isDisabled = isExpired || customerResponse === 'RenewOffer';
    const deadlineDate = valuation?.offer.deadlineDate;
    const { showDeadlineDate } = useFeatureFlags();
    const hasExpectationsEnabled = valuation?.expectations?.isEnabled;

    return (
        <Card
            $topMargin={valuation?.status === ValuationStatus.ACCEPTED}
            transparent
        >
            {isSuccess && customerResponse === 'Declined' && (
                <NetPromoterScore
                    situation={
                        hasExpectationsEnabled
                            ? 'OfferDeclinedExpectationsEnabled'
                            : 'OfferDeclined'
                    }
                />
            )}
            {isLoading && <LoadingOverlay delayMillis={0} fixed />}
            <Label $isDisabled={isDisabled}>{i18next.t('weCanOffer')}</Label>
            <PurchasePrice $isDisabled={isDisabled}>{`${numberFormat(
                offer.purchasePrice
            )} kr`}</PurchasePrice>
            <StyledCollapse
                id="offer-justification"
                backgroundColorVariable="--moller-color-background"
                collapsedHeight="170"
                $isDisabled={isDisabled}
            >
                <Justification>{offer.justification}</Justification>
            </StyledCollapse>
            {attachment && (
                <Attachments>
                    <AttachmentCard key={attachment.id} file={attachment} />
                </Attachments>
            )}
            {showDeadlineDate && !!deadlineDate && !customerResponse && (
                <DeadlineBanner
                    type="info"
                    message={i18next.t('offerValidDeadline', {
                        date: moment(deadlineDate).format(DATE_MONTH_FORMAT),
                    })}
                />
            )}
            {isExpired ? (
                <ExpiredContent>
                    <AlertBanner
                        type="warning"
                        message={
                            parse(i18next.t('offerExpiredMessage')) as string
                        }
                    />
                    <PrimaryButton
                        onClick={() => {
                            updatePriceOffer.mutate('RenewOffer');
                            setCustomerResponse('RenewOffer');
                        }}
                    >
                        {i18next.t('RenewOffer')}
                    </PrimaryButton>
                </ExpiredContent>
            ) : customerResponse && !isError && !isLoading ? (
                <Response customerResponse={customerResponse} />
            ) : (
                <Buttons>
                    <PrimaryButton
                        onClick={() => {
                            updatePriceOffer.mutate('Accepted');
                            setCustomerResponse('Accepted');
                        }}
                    >
                        {i18next.t('Accepted')}
                    </PrimaryButton>
                    <GhostButton
                        onClick={() => {
                            updatePriceOffer.mutate('Declined');
                            setCustomerResponse('Declined');
                        }}
                    >
                        {i18next.t('Declined')}
                    </GhostButton>
                </Buttons>
            )}
            {isError && <Error density="compact" />}
        </Card>
    );
};

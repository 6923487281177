import { useParams } from 'react-router';
import { breakpointDesktop, Headline, Subtitle } from '@moller/design-system';
import i18next from 'i18next';
import { styled } from 'styled-components';
import parse from 'html-react-parser';
import { NewCarBox } from '@/components/NewCarBox';
import { Page as PageComponent } from '../../components/Page';
import { ErrorContent } from './components/ErrorContent';
import { useGetMyPageValuation } from './queries/useGetMyPageValuation';
import { errorType } from './utils/errorType';
import { LoadingOverlay } from '@/components/loading/LoadingOverlay';
import { useNavigate } from 'react-router-dom';
import { useGetMyPage } from './queries/useGetMyPage';
import { PATH_MY_PAGE } from '@/utils/url/routes';
import { PageHeader } from '@/components/PageHeader';
import { MyPageStep, MyPageStepModel } from './components/MyPageStep';
import { CarStepContent } from './components/CarStepContent';
import { Divider } from '@/components/Divider';
import { PriceOffer } from './components/PriceOffer';
import { MyPageValuationModel } from '@/types/MyPageValuationModel';
import { ContractStepContent } from './components/ContractStepContent';
import { PriceEstimate } from './components/price-estimate/PriceEstimate';
import { ResponseAlertMessage } from '@/components/ResponseAlertMessage';
import { ValuationStatus } from '@/utils/enums/ValuationStatus';
import { useState } from 'react';
import { NetPromoterScore } from '@/components/net-promoter-score/NetPromoterScore';
import { SvvDownAlertMessage } from '@/components/SvvDownAlertMessage';

export const Page = styled(PageComponent)`
    > div {
        max-width: 600px;
    }

    a {
        color: var(--moller-color-on-background);
    }
`;

export const PageTitle = styled(Headline)`
    font-size: var(--moller-typeface-size-xl);
    margin: 0 0 var(--moller-spacing-s);
    line-height: var(--moller-typeface-line-height);

    @media screen and (min-width: ${breakpointDesktop}) {
        font-size: var(--moller-typeface-size-xxl);
    }
`;

const Contact = styled.div`
    padding-bottom: var(--moller-size-4xl);

    a {
        color: var(--moller-color-on-background);
    }
`;

const ContactDivider = styled(Divider)`
    margin: var(--moller-spacing-xxs) 0 var(--moller-spacing-xxl);
`;

const NeedNewCarBox = styled(NewCarBox)`
    margin-bottom: var(--moller-spacing-3xl);
`;

export const MyPageView = () => {
    const [isNPSBannerShowing, setShowNPSBanner] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const {
        data: valuation,
        isLoading,
        isError,
        error,
    } = useGetMyPageValuation(id);
    const { data: valuations } = useGetMyPage();
    const hasMultipleValuations = valuations && valuations.length > 1;

    if (isLoading) {
        return <LoadingOverlay delayMillis={0} />;
    }

    const steps: (
        valuation: MyPageValuationModel
    ) => Omit<MyPageStepModel, 'index'>[] = (
        valuation: MyPageValuationModel
    ) => [
        {
            title: 'carSection',
            content: <CarStepContent valuation={valuation} />,
            isDisabled: false,
            isActive: !valuation.offer && !valuation.contract,
        },
        {
            title: 'priceOfferTitle',
            content: <PriceOffer offer={valuation.offer} />,
            isDisabled: !valuation.offer,
            isActive: valuation.offer && !valuation.contract,
            description: 'priceStepDescription',
        },
        {
            title: 'signContract',
            content: <ContractStepContent valuation={valuation} />,
            isDisabled: !valuation.contract,
            isActive: !!valuation.contract,
            description: 'contractStepDescription',
        },
    ];

    return (
        <>
            <PageHeader
                titleTextKey="sellMyCar"
                backButtonOnClick={
                    hasMultipleValuations
                        ? () => navigate(PATH_MY_PAGE)
                        : undefined
                }
                backButtonTextKey="backToOverview"
            />
            <Page>
                {!valuation?.car.brand && <SvvDownAlertMessage />}
                {isError ? (
                    <ErrorContent errorType={errorType(error)} />
                ) : (
                    valuation && (
                        <>
                            {(valuation.status === ValuationStatus.NEW ||
                                valuation.status ===
                                    ValuationStatus.DECLINED) &&
                            valuation.priceEstimate &&
                            valuation.priceEstimate.customerResponse !==
                                'Interested' ? (
                                <PriceEstimate
                                    priceEstimate={valuation.priceEstimate}
                                    showNPSBanner={() => setShowNPSBanner(true)}
                                />
                            ) : (
                                <>
                                    {isNPSBannerShowing && (
                                        <NetPromoterScore situation="PriceEstimateAccepted" />
                                    )}
                                    <ResponseAlertMessage />
                                    {steps(valuation).map((step, index) => (
                                        <MyPageStep
                                            step={{ ...step, index }}
                                            key={step.title}
                                        />
                                    ))}
                                    <ContactDivider />
                                    <Contact>
                                        <Subtitle>
                                            {i18next.t('contactTitle')}
                                        </Subtitle>
                                        <p>
                                            {parse(
                                                i18next.t('contactDescription')
                                            )}
                                        </p>
                                    </Contact>
                                    <NeedNewCarBox />
                                </>
                            )}
                        </>
                    )
                )}
            </Page>
        </>
    );
};

import moment from 'moment';

export const DATE_INPUT_FORMAT = 'yyyy-MM-DD';
export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm';
export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_PRETTY_FORMAT = 'DD. MMMM YYYY';
export const DATE_MONTH_FORMAT = 'DD. MMMM';
export const DATE_TIME_SHORT_FORMAT = 'DD. MMM HH:mm';
export const DATE_TIME_PRETTY_FORMAT = 'DD. MMM YYYY [kl.] HH:mm';
export const YESTERDAY_TIME = '[I går, kl.] HH:mm';

export const timeSinceFormat = (date: string) => {
    const dateString = moment(date);
    if (dateString.format(DATE_FORMAT) === moment().format(DATE_FORMAT)) {
        return moment(date).startOf('minutes').fromNow();
    }
    if (
        dateString.format(DATE_FORMAT) ===
        moment().subtract(1, 'days').format(DATE_FORMAT)
    ) {
        return dateString.format(YESTERDAY_TIME);
    }
    return dateString.format(DATE_TIME_PRETTY_FORMAT);
};

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MY_VALUATION_SELF_DECLARATION } from '@/http/endpoints';
import { SelfDeclarationModel } from '@/types/SelfDeclarationModel';
import format from 'string-format';
import { post } from '@/http/post';
import {
    SelfDeclarationElectricCarFormModel,
    SelfDeclarationFormModel,
} from '../utils/selfDeclarationFormUtils';

export const usePostSelfDeclaration = (id: string | undefined) => {
    const postSelfDeclaration = (
        selfDeclaration:
            | SelfDeclarationFormModel
            | SelfDeclarationElectricCarFormModel
    ) =>
        post<SelfDeclarationModel, unknown>(
            format(MY_VALUATION_SELF_DECLARATION, id || ''),
            selfDeclaration
        ).request;
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (
            selfDeclaration:
                | SelfDeclarationFormModel
                | SelfDeclarationElectricCarFormModel
        ) => postSelfDeclaration(selfDeclaration),
        onSuccess: () => {
            void queryClient.invalidateQueries({
                queryKey: ['myPageValuation', id],
            });
        },
    });
};

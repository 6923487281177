import { useParams } from 'react-router';
import { PATH_MY_PAGE } from '@/utils/url/routes';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '@/components/PageHeader';
import { tokens } from '@moller/gnist-themes/tokens.css.js';
import { Page } from '@/components/Page';
import i18next from 'i18next';
import { ExpectationsForm } from '@/views/expectations-view/components/ExpectationsForm';
import { styled } from 'styled-components';
import { useGetMyPageValuation } from '@/views/my-page/queries/useGetMyPageValuation';
import { SimilarCarsWrapper } from '@/views/expectations-view/components/SimilarCarsWrapper';
import { useState } from 'react';
import { AlertBanner } from '@moller/design-system';

const IntroText = styled.p`
    margin-bottom: var(--moller-spacing-m);
`;

export const Subtitle = styled.h2`
    display: flex;
    font-size: var(--moller-typeface-size-base);
    font-weight: 500;
    margin: var(--moller-spacing-xs) 0 var(--moller-spacing-xxs);
`;

const Banner = styled(AlertBanner)`
    margin-bottom: var(--moller-spacing-xl);
`;

export const ExpectationsView = () => {
    const [isSuccess, setIsSuccess] = useState(false);
    const { id } = useParams<{ id: string }>();
    const { data: valuation } = useGetMyPageValuation(id);
    const navigate = useNavigate();
    const expectations = valuation?.expectations;

    return (
        <>
            {id && (
                <PageHeader
                    titleTextKey={
                        valuation?.expectations?.similarCarUrl
                            ? 'expectationsTitle'
                            : 'addExpectationsTitle'
                    }
                    backButtonOnClick={() => navigate(`${PATH_MY_PAGE}/${id}`)}
                    backButtonTextKey="toFrontpage"
                />
            )}
            <Page>
                {isSuccess && (
                    <Banner
                        type="success"
                        message={i18next.t('expectationsSuccessMessage')}
                    />
                )}
                <IntroText>{i18next.t('expectationsDescription')}</IntroText>
                {expectations?.similarCarUrl ? (
                    <>
                        <SimilarCarsWrapper />
                        <Subtitle style={{ marginTop: tokens.spacing.xl }}>
                            {i18next.t('expectationsCommentTitle')}
                        </Subtitle>
                        <p style={{ whiteSpace: 'pre-line' }}>
                            {expectations.comment}
                        </p>
                    </>
                ) : (
                    <ExpectationsForm setIsSuccess={setIsSuccess} />
                )}
                <Subtitle>{i18next.t('aboutFinnPricesTitle')}</Subtitle>
                <p>{i18next.t('aboutFinnPricesBody')}</p>
                <Subtitle>{i18next.t('aboutMarketPriceTitle')}</Subtitle>
                <p>{i18next.t('aboutMarketPriceBody')}</p>
            </Page>
        </>
    );
};

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MY_VALUATION_EXPECTATIONS } from '@/http/endpoints';
import format from 'string-format';
import { post } from '@/http/post';
import { ExpectationsFormModel } from '@/views/expectations-view/utils/expectationsFormUtils';

export const usePostExpectations = (id: string | undefined) => {
    const postExpectations = (expectations: ExpectationsFormModel) =>
        post<ExpectationsFormModel, unknown>(
            format(MY_VALUATION_EXPECTATIONS, id || ''),
            expectations
        ).request;
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (expectations: ExpectationsFormModel) =>
            postExpectations(expectations),
        onSuccess: () => {
            void queryClient.invalidateQueries({
                queryKey: ['myPageValuation', id],
            });
        },
    });
};

import { styled } from 'styled-components';

export const Divider = styled.hr`
    width: 100%;
    border: none;
    height: 0.5px;
    background-color: var(--moller-color-outline);
    margin: var(--moller-spacing-l) 0;
    display: flex;
`;

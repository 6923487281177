import axios, { AxiosResponse, AxiosRequestConfig, AxiosInstance } from 'axios';
import { getCurrentEnvironment } from './utils/getCurrentEnvironment';
import { headers } from './utils/headers';

export function post<T = never, D = never>(
    path: string,
    data?: D,
    config: AxiosRequestConfig = {}
): {
    request: Promise<AxiosResponse<T>>;
    cancel: () => void;
} {
    const { CancelToken } = axios;
    const source = CancelToken.source();

    const { apiUrl } = getCurrentEnvironment();
    const httpClient: AxiosInstance = axios;
    const post = () => {
        const axiosConfig = {
            headers,
            withCredentials: true,
            ...config,
        };
        return httpClient.post<T>(`${apiUrl}/${path}`, data, {
            ...axiosConfig,
            cancelToken: source.token,
        });
    };

    return {
        request: post(),
        cancel: () => source.cancel(),
    };
}

import i18next from 'i18next';
import { styled } from 'styled-components';
import parse from 'html-react-parser';

const PrivacyInfo = styled.p`
    font-size: var(--moller-typeface-size-s);
    padding: var(--moller-spacing-s) 0;
`;

export const SigningStep = () => {
    return (
        <div>
            <p>{i18next.t('signingStepInfo')}</p>
            <PrivacyInfo>{parse(i18next.t('signingPrivacyInfo'))}</PrivacyInfo>
        </div>
    );
};

import { MY_VALUATION_IMAGES } from '@/http/endpoints';
import { get } from '@/http/get';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
import format from 'string-format';
import { ImageModel } from '@/types/ImageModel';

export const useGetImages = (enabled = true) => {
    const { id } = useParams<{ id: string }>();
    const valuationId = Number(id);

    const getImages = () =>
        get<ImageModel[]>(format(MY_VALUATION_IMAGES, id || '')).request.then(
            (res) => res.data
        );

    return useQuery({
        queryKey: ['images', valuationId],
        queryFn: getImages,
        enabled: enabled && !!valuationId,
    });
};

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getCurrentEnvironment } from './utils/getCurrentEnvironment';
import { headers } from './utils/headers';

export function get<T = never>(
    path: string,
    config: AxiosRequestConfig = {}
): {
    request: Promise<AxiosResponse<T>>;
    cancel: () => void;
} {
    const { CancelToken } = axios;
    const source = CancelToken.source();

    const { apiUrl } = getCurrentEnvironment();
    const httpClient: AxiosInstance = axios;
    const get = () => {
        const axiosConfig = {
            headers,
            withCredentials: true,
            ...config,
        };
        return httpClient.get<T>(`${apiUrl}/${path}`, {
            ...axiosConfig,
            cancelToken: source.token,
        });
    };

    return {
        request: get(),
        cancel: () => source.cancel(),
    };
}

import { FormRadioGroup } from '../../my-page-contract/components/FormRadioGroup';
import {
    AlertBanner,
    formInputs,
    FormProvider,
    useLocalFormState,
} from '@moller/design-system';
import i18next from 'i18next';
import { styled } from 'styled-components';
import {
    initialSelfDeclarationElectricCarFormValues,
    initialSelfDeclarationFormValues,
    SelfDeclarationElectricCarFormModel,
    selfDeclarationElectricCarFormValidator,
    SelfDeclarationFormModel,
    selfDeclarationFormValidator,
} from '../utils/selfDeclarationFormUtils';
import { ChargingCablesRadioGroup } from './ChargingCablesRadioGroup';
import { LocalFormState } from '@moller/design-system/utilities/forms/useLocalFormState.js';
import { ReactElement, useEffect } from 'react';
import { SelfDeclarationModel } from '@/types/SelfDeclarationModel';

const { TextArea } = formInputs<SelfDeclarationFormModel>();

const TextAreaWrapper = styled.div`
    padding-top: var(--moller-spacing-xxs);
    width: 100%;

    > span {
        width: 100%;
    }
`;

const ExtraInfoBanner = styled(AlertBanner)`
    margin-top: var(--moller-spacing-xl);
`;

interface SelfDeclarationFormProps {
    readonly onSubmit: (
        data: SelfDeclarationFormModel | SelfDeclarationElectricCarFormModel
    ) => void;
    readonly formBottomContent: ReactElement;
    readonly selfDeclaration?: SelfDeclarationModel;
    readonly isElectric: boolean;
    readonly hideNecessityText?: boolean;
}

export const SelfDeclarationForm = ({
    onSubmit,
    formBottomContent,
    selfDeclaration,
    isElectric,
    hideNecessityText = false,
}: SelfDeclarationFormProps) => {
    const electricCarForm =
        useLocalFormState<SelfDeclarationElectricCarFormModel>(
            initialSelfDeclarationElectricCarFormValues(selfDeclaration),
            selfDeclarationElectricCarFormValidator
        );
    const nonElectricCarForm = useLocalFormState<SelfDeclarationFormModel>(
        initialSelfDeclarationFormValues(selfDeclaration),
        selfDeclarationFormValidator
    );
    const form: LocalFormState<
        SelfDeclarationFormModel | SelfDeclarationElectricCarFormModel
    > = isElectric ? electricCarForm : nonElectricCarForm;

    useEffect(() => {
        const state = form.state.validated;
        if (state.hasBeenDamaged === false && !!state.damageInfo?.length) {
            form.inputProps('damageInfo').setValue('');
        }
        if (state.hasKnownFaults === false && !!state.knownFaultsInfo?.length) {
            form.inputProps('knownFaultsInfo').setValue('');
        }
    }, [form]);

    return (
        <FormProvider
            id="selfDeclarationForm"
            form={form}
            onSubmit={(data) => onSubmit(data)}
            hideNecessityText={hideNecessityText}
        >
            <FormRadioGroup form={form} field="hasTwoKeys" />
            <FormRadioGroup form={form} field="hasTwoTireSets" />
            {isElectric && (
                <ChargingCablesRadioGroup
                    form={form}
                    field="chargingCablesIncluded"
                />
            )}
            <FormRadioGroup form={form} field="isTrimmed" />
            <FormRadioGroup form={form} field="hasBeenDamaged" />
            {!!form.state.validated.hasBeenDamaged && (
                <TextAreaWrapper>
                    <TextArea
                        field="damageInfo"
                        label={i18next.t('damageInfo')}
                    />
                </TextAreaWrapper>
            )}
            <FormRadioGroup form={form} field="hasKnownFaults" />
            {!!form.state.validated.hasKnownFaults && (
                <TextAreaWrapper>
                    <TextArea
                        field="knownFaultsInfo"
                        label={i18next.t('knownFaultsInfo')}
                    />
                </TextAreaWrapper>
            )}
            <ExtraInfoBanner
                message={i18next.t('extraEquipmentInfo')}
                type="info"
                density="compact"
            />
            {formBottomContent}
        </FormProvider>
    );
};

import { MyPageValuationModel } from '@/types/MyPageValuationModel';
import { ContractInfo } from './ContractInfo';
import { InfoCard } from '@moller/design-system';
import i18next from 'i18next';
import { numberFormat } from '@/utils/numberFormat';
import { css, styled } from 'styled-components';

const Card = styled(InfoCard)`
    border-color: var(--moller-color-outline);
`;

const Description = styled.p<{ $largePadding: boolean }>`
    ${(props) => css`
        padding-bottom: ${props.$largePadding
            ? 'var(--moller-spacing-l)'
            : 'var(--moller-spacing-s)'};
    `}
`;

export const ContractStepContent = ({
    valuation,
}: {
    valuation: MyPageValuationModel;
}) => {
    const isSigned =
        valuation.contract?.pdfAvailable &&
        valuation.contract.status === 'Signed';

    return (
        <Card transparent>
            <Description $largePadding={isSigned}>
                {isSigned
                    ? i18next.t('contractSignedDescription')
                    : i18next.t('contractSentDescription', {
                          purchasePrice: numberFormat(
                              valuation.contract?.purchasePrice || ''
                          ),
                          car: `${valuation.car?.brand || ''} ${
                              valuation.car?.model || ''
                          }`,
                          regNo: valuation.car?.registrationNumber,
                      })}
            </Description>
            <ContractInfo />
        </Card>
    );
};

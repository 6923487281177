import React from 'react';
import { createRoot } from 'react-dom/client';
import { FlagProvider } from '@unleash/proxy-client-react';
import { App } from './App';
import { getFeatureToggleClient } from './utils/feature-toggle/featureToggleEnvironment';
import { getCurrentEnvironmentName } from './http/utils/getCurrentEnvironment';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { initializeDatadog } from './utils/tracking/initializeDatadog';
import { initializeGTM } from './utils/tracking/initializeGTM';

initializeGTM();
initializeDatadog();
const envName = getCurrentEnvironmentName();
const unleashConfig = {
    url: getFeatureToggleClient(envName).url,
    clientKey: getFeatureToggleClient(envName).key,
    appName: 'Bruktbilx2',
};

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: 60 * (60 * 1000), // 1 hour
            staleTime: 5 * (60 * 1000), // 5 minutes
            retry: false,
        },
    },
});

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <QueryClientProvider client={queryClient}>
        <FlagProvider config={unleashConfig}>
            <React.StrictMode>
                <App />
            </React.StrictMode>
        </FlagProvider>
        {envName === 'local' && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
);

import { css, styled } from 'styled-components';

export type Step = string;

interface ProgressBarProps {
    readonly steps: Step[];
    readonly activeStep: Step;
}

const TextWrapper = styled.div`
    display: flex;
    padding-bottom: var(--moller-spacing-xxs);
`;

const ProgressCount = styled.p`
    font-size: var(--moller-typeface-size-xs);
    padding-right: var(--moller-spacing-xxs);
    min-width: var(--moller-size-s);
`;

const Bar = styled.div`
    background-color: var(--moller-palette-neutral-40);
    height: 6px;
    border-radius: 3px;
    display: flex;
`;

const BarFilled = styled(Bar)<{ $width: number }>`
    ${(props) => css`
        background-color: var(--moller-palette-primary-10);
        width: ${props.$width}%;
        border-radius: ${props.$width === 100 ? '3px' : '3px 0 0 3px'};
        transition: width linear 250ms;
    `}
`;

export const ProgressBar = ({ steps, activeStep }: ProgressBarProps) => {
    const progressCountLabel = `Steg ${steps.indexOf(activeStep) + 1} av ${
        steps.length
    }`;
    const progressFilledWidth =
        (100 / steps.length) * (steps.indexOf(activeStep) + 1);

    return (
        <>
            <TextWrapper>
                <ProgressCount aria-label={progressCountLabel}>
                    {progressCountLabel}
                </ProgressCount>
            </TextWrapper>
            <Bar>
                <BarFilled $width={progressFilledWidth} />
            </Bar>
        </>
    );
};

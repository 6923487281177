import { Delayed } from './Delayed';
import { styled } from 'styled-components';

const SpinningIcon = styled.div`
    display: flex;
    height: var(--moller-size-3xl);
    width: var(--moller-size-3xl);
    border-radius: 50%;
    background: transparent;
    border: 8px solid var(--moller-color-primary);
    border-bottom-color: transparent;
    animation: spinner 1s cubic-bezier(0.4, 0.5, 0.7, 0.8) infinite,
        spinner-fade-in ease 0.5s 1;
    animation-fill-mode: forwards;

    @keyframes spinner {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes spinner-fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;

export const Spinner = ({ delayMillis }: { delayMillis?: number }) => (
    <Delayed delayMillis={delayMillis}>
        <SpinningIcon />
    </Delayed>
);

import { TextButton, PrimaryButton } from '@moller/design-system';
import i18next from 'i18next';
import { css, styled } from 'styled-components';

interface FormButtonsProps {
    primaryAction: {
        textKey: string;
        onClick?: () => void;
    };
    secondaryAction: {
        onClick: () => void;
        textKey: string;
    };
    extraMargin?: boolean;
}

const ButtonsWrapper = styled.div<{ $extraMargin: boolean }>`
    ${(props) => css`
        margin: var(
                ${props.$extraMargin
                    ? '--moller-spacing-xl'
                    : '--moller-spacing-s'}
            )
            0 var(--moller-spacing-s);
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button:first-of-type {
            margin-right: var(--moller-spacing-xl);
        }
    `}
`;

export const FormButtons = ({
    primaryAction,
    secondaryAction,
    extraMargin = false,
}: FormButtonsProps) => {
    return (
        <ButtonsWrapper $extraMargin={extraMargin}>
            <TextButton onClick={() => secondaryAction.onClick()} type="button">
                {i18next.t(secondaryAction.textKey)}
            </TextButton>
            <PrimaryButton type="submit" onClick={primaryAction.onClick}>
                {i18next.t(primaryAction.textKey)}
            </PrimaryButton>
        </ButtonsWrapper>
    );
};

import { css, styled } from 'styled-components';
import { Headline, Icon } from '@moller/design-system';
import { ReactElement, useState } from 'react';
import i18next from 'i18next';

const Wrapper = styled.div<{ $isDisabled: boolean }>`
    ${(props) => css`
        margin: var(--moller-spacing-xxs) 0 var(--moller-spacing-xl);
        ${props.$isDisabled &&
        '* { color: var(--moller-color-on-disabled) !important; }'}
    `}
`;

const Content = styled.div<{
    $isCollapsed: boolean;
}>`
    ${(props) => css`
        position: relative;
        overflow: hidden;
        height: ${props.$isCollapsed ? '0' : 'auto'};
        margin: calc(var(--moller-spacing-xxs) * -1);
        padding: var(--moller-spacing-xxs);

        > div {
            padding: var(--moller-spacing-s) 0 0;
        }
    `}
`;

const StepIndex = styled.p`
    color: var(--moller-color-tertiary-variant);
    font-weight: 500;
    font-size: var(--moller-typeface-size-s);
    line-height: var(--moller-typeface-line-height);
    text-transform: uppercase;
`;

const Description = styled.p`
    margin-top: var(--moller-spacing-s);
`;

const Toggle = styled.button`
    background-color: transparent;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    padding: 0;
    color: var(--moller-color-primary);
    cursor: pointer;

    &:disabled {
        cursor: auto;
    }

    ${Headline.selector} {
        margin: 0 var(--moller-spacing-xxs) 0 0;
    }
`;

export interface MyPageStepModel {
    index: number;
    title: string;
    description?: string;
    content: ReactElement;
    isActive: boolean;
    isDisabled: boolean;
}

export const MyPageStep = ({ step }: { step: MyPageStepModel }) => {
    const [isCollapsed, setIsCollapsed] = useState(!step.isActive);

    return (
        <Wrapper $isDisabled={step.isDisabled}>
            <StepIndex>
                {i18next.t('stepIndex', { index: step.index + 1 })}
            </StepIndex>
            <Toggle
                onClick={() => setIsCollapsed(!isCollapsed)}
                disabled={step.isDisabled}
            >
                <Headline>{i18next.t(step.title)}</Headline>
                {!step.isDisabled && (
                    <Icon icon={isCollapsed ? 'expand_more' : 'expand_less'} />
                )}
            </Toggle>
            {step.description && step.isDisabled && (
                <Description>{i18next.t(step.description)}</Description>
            )}
            {!step.isDisabled && (
                <Content $isCollapsed={isCollapsed}>
                    <div>{step.content}</div>
                </Content>
            )}
        </Wrapper>
    );
};

import { styled } from 'styled-components';
import { Headline } from '@moller/design-system';
import i18next from 'i18next';
import { useState } from 'react';
import { MyPageContractForm } from './components/MyPageContractForm';
import { ProgressBar, Step } from './components/ProgressBar';
import { useParams } from 'react-router';
import { ErrorContent } from '../my-page/components/ErrorContent';
import { useGetMyPageValuation } from '../my-page/queries/useGetMyPageValuation';
import { errorType } from '../my-page/utils/errorType';
import { LoadingOverlay } from '@/components/loading/LoadingOverlay';
import { PATH_MY_PAGE } from '@/utils/url/routes';
import { PageHeader } from '@/components/PageHeader';
import { useNavigate } from 'react-router-dom';
import { Page } from '@/components/Page';

const ContractPage = styled(Page)`
    .b-page__content {
        padding: var(--moller-spacing-s) var(--moller-spacing-l);
    }
`;

export const FormHeadline = styled(Headline)`
    margin: var(--moller-spacing-s) 0 var(--moller-spacing-xl);
`;

export enum MyPageContractStep {
    CUSTOMER = 'CUSTOMER',
    CAR = 'CAR',
    PAYMENT = 'PAYMENT',
    SIGNING = 'SIGNING',
}

export const formSteps: Step[] = [
    MyPageContractStep.CUSTOMER,
    MyPageContractStep.PAYMENT,
    MyPageContractStep.SIGNING,
];

export const formStepsWithSelfDeclaration: Step[] = [
    MyPageContractStep.CUSTOMER,
    MyPageContractStep.CAR,
    MyPageContractStep.PAYMENT,
    MyPageContractStep.SIGNING,
];

export const MyPageContractView = () => {
    const { id } = useParams<{ id: string }>();
    const {
        data: valuation,
        isLoading,
        isError,
        error,
    } = useGetMyPageValuation(id);
    const steps = valuation?.selfDeclaration
        ? formStepsWithSelfDeclaration
        : formSteps;
    const [activeStep, setActiveStep] = useState(steps[0]);
    const navigate = useNavigate();

    const pageTitle = (step: string) => {
        switch (step) {
            case MyPageContractStep.CUSTOMER:
                return 'stepTitleCustomer';
            case MyPageContractStep.CAR:
                return 'stepTitleCar';
            case MyPageContractStep.PAYMENT:
                return 'stepTitlePayment';
            case MyPageContractStep.SIGNING:
                return 'stepTitleSigning';
            default:
                return '';
        }
    };

    if (isLoading) {
        return <LoadingOverlay delayMillis={0} />;
    }

    return (
        <>
            {id && (
                <PageHeader
                    titleTextKey="signContract"
                    backButtonOnClick={() => navigate(`${PATH_MY_PAGE}/${id}`)}
                    backButtonTextKey="toFrontpage"
                />
            )}
            <ContractPage>
                {isError ? (
                    <ErrorContent errorType={errorType(error)} />
                ) : (
                    <>
                        <ProgressBar steps={steps} activeStep={activeStep} />
                        <FormHeadline>
                            {i18next.t(pageTitle(activeStep))}
                        </FormHeadline>
                        <MyPageContractForm
                            activeStep={activeStep}
                            steps={steps}
                            setActiveStep={setActiveStep}
                        />
                    </>
                )}
            </ContractPage>
        </>
    );
};

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MY_VALUATION_IMAGES } from '@/http/endpoints';
import { post } from '@/http/post';
import format from 'string-format';
import { ImageModel } from '@/types/ImageModel';

export const useSaveImages = (
    id: string | undefined,
    onSuccess: () => void
) => {
    const saveImages = (images: ImageModel[]) =>
        post(format(MY_VALUATION_IMAGES, id || ''), images).request;
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (images: ImageModel[]) => saveImages(images),
        onSuccess: () => {
            void queryClient.invalidateQueries({
                queryKey: ['myPageValuation', id],
            });
            void queryClient
                .invalidateQueries({
                    queryKey: ['images', Number(id)],
                })
                .then(() => onSuccess());
        },
    });
};

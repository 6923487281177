import { useMutation } from '@tanstack/react-query';
import { confirmRequest } from '../http/confirmRequest';
import { DealerModel } from '@/types/DealerModel';
import { ValuationType } from '@/utils/enums/ValuationType';
import { useGetUrlParams } from '../utils/useGetUrlParams';
import { SelfDeclarationModel } from '@/types/SelfDeclarationModel';

export type RequestModel = {
    phoneNumber?: string;
    email: string;
    registrationNumber: string;
    mileage: string;
    firstName?: string;
    lastName?: string;
    zipCode?: string;
    dealerNumber: string;
    desiredDeliveryDate?: string;
    comment?: string;
    acceptedTerms?: boolean;
    purchaseHelpDesired?: boolean;
    selfDeclaration?: SelfDeclarationModel;
};

export const useConfirmRequest = (valuationType: ValuationType) => {
    const { uuidFromUrl, srcFromUrl } = useGetUrlParams();

    return useMutation({
        mutationFn: ({
            requestForm,
            selectedDealer,
            authToken,
        }: {
            requestForm: RequestModel;
            selectedDealer: DealerModel | undefined;
            authToken: string | undefined;
        }) =>
            confirmRequest(
                requestForm,
                selectedDealer || undefined,
                uuidFromUrl ? ValuationType.PROACTIVE : valuationType,
                authToken,
                uuidFromUrl,
                srcFromUrl
            ),
    });
};

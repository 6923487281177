import { DEALERS } from '@/http/endpoints';
import { get } from '@/http/get';
import { DealerModel } from '@/types/DealerModel';
import { useQuery } from '@tanstack/react-query';

export const useGetDealers = () => {
    const getDealers = () =>
        get<DealerModel[]>(`${DEALERS}?dealergroup=mollerbil`).request.then(
            (res) => res.data
        );

    return useQuery({
        queryKey: ['dealers'],
        queryFn: getDealers,
    });
};

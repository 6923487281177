import { VEHICLE_APPROVALS } from '@/http/endpoints';
import { post } from '@/http/post';
import { useMutation } from '@tanstack/react-query';
import { RejectionType } from '@/utils/enums/RejectionType';

interface VehicleApprovalsResponse {
    customerLoginAvailable: boolean;
    rejected: boolean;
    rejectionReason: { type: RejectionType };
}

export const useVehicleApprovals = (
    registrationNumber: string | undefined,
    mileage: string | undefined,
    purchaseHelpDesired: boolean | undefined,
    isProactiveLead = false
) => {
    const checkVehicleApprovals = () =>
        post<VehicleApprovalsResponse>(
            `${VEHICLE_APPROVALS}/${registrationNumber || ''}/?mileage=${
                mileage || ''
            }&purchaseHelpDesired=${
                purchaseHelpDesired?.toString() || ''
            }&isProactiveLead=${isProactiveLead?.toString() || ''}`
        ).request.then((res) => res.data);

    return useMutation({
        mutationFn: () => checkVehicleApprovals(),
    });
};

import { AlertBanner } from '@moller/design-system';
import { css, styled } from 'styled-components';
import { useFeatureFlags } from '@/utils/feature-toggle/useFeatureFlags';

export const Alert = styled(AlertBanner)<{ $topMargin?: boolean }>`
    ${(props) => css`
        ${props.$topMargin
            ? 'margin-top: var(--moller-spacing-xl)'
            : 'margin-bottom: var(--moller-spacing-m)'};
    `}
`;

export const ResponseAlertMessage = ({
    topMargin,
}: {
    topMargin?: boolean;
}) => {
    const { responseAlertMessage } = useFeatureFlags();

    if (responseAlertMessage && responseAlertMessage.message)
        return (
            <Alert
                message={responseAlertMessage.message}
                heading={responseAlertMessage.heading || ''}
                type={responseAlertMessage.type || 'info'}
                $topMargin={topMargin}
            />
        );
    else return <></>;
};

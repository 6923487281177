import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MY_VALUATION_OFFER } from '@/http/endpoints';
import { put } from '@/http/put';
import format from 'string-format';
import { PriceOfferCustomerResponse } from '@/types/OfferModel';

export const useUpdatePriceOffer = (
    valuationId: string | undefined,
    offerId: string
) => {
    const updatePriceEstimate = (
        customerResponse: PriceOfferCustomerResponse
    ) =>
        put<{ customerResponse: PriceOfferCustomerResponse }, unknown>(
            format(MY_VALUATION_OFFER, valuationId || '', offerId),
            {
                customerResponse,
            }
        ).request;
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (customerResponse: PriceOfferCustomerResponse) =>
            updatePriceEstimate(customerResponse),
        onSuccess: () =>
            void queryClient.invalidateQueries({
                queryKey: ['myPageValuation', valuationId],
            }),
    });
};

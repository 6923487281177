import { useEffect, useState } from 'react';
import { getCity } from '../http/getCity';

export const useGetCity = (zipCode: string | undefined) => {
    const [city, setCity] = useState('');

    useEffect(() => {
        if (zipCode && !city) {
            void getCity(zipCode).then((res) => setCity(res.data.city));
        }
        if (!zipCode && city) {
            setCity('');
        }
    }, [city, zipCode]);

    return { city };
};
